import {useState} from 'react';

import type {DataTypeProvider} from '@devexpress/dx-react-grid';
import SaveAltIcon from '@mui/icons-material/SaveAltRounded';
import CircularProgress from '@mui/material/CircularProgress';

import {TableActionButtonBase} from './TableActionButtonBase';
import {type DownloadStatus} from '../../../types/DownloadStatus';
import {type VantageTableConfig} from '../hooks/useVantageTableConfig';
import {type ActionsTypeProviderFormatterProps} from '../providers/ActionsTypeProviderFormatter';

export function TableActionDownloadItem<TableData extends object>({
  config,
  row,
  onDownloadItem,
}: Pick<DataTypeProvider.ValueFormatterProps, 'row'> & {
  onDownloadItem: ActionsTypeProviderFormatterProps<TableData>['rowActions']['onDownloadItem'];
  config: VantageTableConfig<TableData>;
}) {
  const [status, setStatus] = useState<DownloadStatus>('idle');

  const handleDownload = async () => {
    setStatus('downloading');
    const response = await onDownloadItem?.(row as TableData);
    if (response === 'catch') {
      setStatus('error');
    } else if (response === 'success') {
      setStatus('success');
    }
  };

  if (onDownloadItem == null) {
    return null;
  }

  return (
    <TableActionButtonBase
      data-cy="TableActionsDownloadItem"
      onClick={handleDownload}
      disabled={
        config.enableRowDownload != null &&
        !config.enableRowDownload(row as TableData)
      }
    >
      {status === 'downloading' ? (
        <CircularProgress size={18} />
      ) : (
        config?.downloadIcon ?? <SaveAltIcon />
      )}
    </TableActionButtonBase>
  );
}
