import {type DataTypeProvider} from '@devexpress/dx-react-grid';

import {
  ActionsTypeProviderEditorHeader,
  type ActionsTypeProviderEditorHeaderProps,
} from './ActionsTypeProviderEditorHeader';
import {
  ActionsTypeProviderEditorRow,
  type ActionsTypeProviderEditorRowProps,
} from './ActionsTypeProviderEditorRow';

export type ActionsTypeProviderEditorProps<TableData extends object> =
  ActionsTypeProviderEditorHeaderProps<TableData> &
    ActionsTypeProviderEditorRowProps<TableData>;

export function ActionsTypeProviderEditor<TableData extends object>({
  row,
  config,
  setAddedRows,
  data,
  actions,
  rowId,
  setEditingRowIds,
  onInlineEdit,
  onInlineAdd,
  setRowChanges,
  rowChanges,
  addedRows,
  ...props
}: DataTypeProvider.ValueEditorProps &
  ActionsTypeProviderEditorProps<TableData>) {
  if (row == null) {
    return (
      <ActionsTypeProviderEditorHeader
        config={config}
        setAddedRows={setAddedRows}
        data={data}
        actions={actions}
      />
    );
  }

  return (
    <ActionsTypeProviderEditorRow
      {...props}
      row={row}
      rowId={rowId}
      setEditingRowIds={setEditingRowIds}
      setAddedRows={setAddedRows}
      onInlineAdd={onInlineAdd}
      onInlineEdit={onInlineEdit}
      setRowChanges={setRowChanges}
      rowChanges={rowChanges}
      addedRows={addedRows}
    />
  );
}
