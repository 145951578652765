/**
 * Created by waldo on 2017/07/19.
 * Re-created by jaco on 2024/05/07
 */

import {useEffect, useMemo} from 'react';
import {RouterProvider} from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import {AuthWrapper} from './modules/auth/AuthWrapper';
import {useThemeStore} from './modules/common/stores/themeStore';
import {router} from './router/router';
import {getTheme} from './theme/getTheme';
import {Toastify} from './Toastify';

export function App() {
  const themeMode = useThemeStore((state) => state.themeMode);
  const whiteLabelledThemeOptions = useThemeStore(
    (state) => state.whiteLabelledThemeOptions,
  );

  const theme = useMemo(
    () => createTheme(getTheme(themeMode, whiteLabelledThemeOptions)),
    [themeMode, whiteLabelledThemeOptions],
  );

  useEffect(() => {
    const el = document.querySelector('#loading-vantage-message-container');
    if (el != null) {
      el.remove();
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <AuthWrapper>
            <RouterProvider router={router} />
            <Toastify />
          </AuthWrapper>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
