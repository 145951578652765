import {type InferType, object, date, string} from 'yup';

import {requiredListing} from '../../common/components/form/validationSchemas';
import {VantageAutocompleteFieldWithListing} from '../../common/components/form/VantageAutocompleteFieldWithListing';
import {VantageAutocompleteFieldWithNodeListing} from '../../common/components/form/VantageAutocompleteFieldWithNodeListing';
import {VantageDateTimePickerField} from '../../common/components/form/VantageDateTimePickerField';
import {VantageForm} from '../../common/components/form/VantageForm';
import {VantageFormActions} from '../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../common/components/form/VantageFormContent';
import {VantageFormFieldsRow} from '../../common/components/form/VantageFormFieldsRow';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {VantageSelectField} from '../../common/components/form/VantageSelectField';
import {VantageTextField} from '../../common/components/form/VantageTextField';

const orderAddFormSchema = object({
  orderNumber: string(),
  transporter: requiredListing.required('Required'),
  customer: requiredListing.required('Required'),
  primeMover: string(),
  upliftPoint: requiredListing.required('Required'),
  offloadPoint: requiredListing.required('Required'),
  status: string().required('Required'),
  depotDeparture: date().required('Required'),
  actualDispatch: date(),
  deliverByDateTime: date(),
  tripDate: date(),
  contract: requiredListing.required('Required'),
  deliveryNoteNumber: string(),
  customerReferenceNumber: string(),
});

export type OrderAddFormValues = InferType<typeof orderAddFormSchema>;

const initialValues: OrderAddFormValues = {
  orderNumber: '',
  customer: {value: 0, label: ''},
  primeMover: '',
  upliftPoint: {value: 0, label: ''},
  offloadPoint: {
    value: 0,
    label: '',
  },
  transporter: {
    value: 0,
    label: '',
  },
  status: 'Planned',
  depotDeparture: new Date(),
  actualDispatch: new Date(),
  deliverByDateTime: new Date(),
  contract: {value: 0, label: ''},
  deliveryNoteNumber: '',
  customerReferenceNumber: '',
};

export interface OrderAddFormProps {
  onOrderSubmit: (values: OrderAddFormValues) => void;
}

const statuses = ['Planned', 'Loaded', 'Offloaded', 'Completed', 'Cancelled'];
export function OrderAddForm({onOrderSubmit}: OrderAddFormProps) {
  return (
    <VantageForm
      permission="order:add"
      initialValues={initialValues}
      onSubmit={onOrderSubmit}
      validationSchema={orderAddFormSchema}
    >
      <VantageFormContent>
        <VantageTextField
          name="orderNumber"
          label="Order Number"
          placeholder="Enter the order number"
          formik
        />
        <VantageFormFieldsRow>
          <VantageDateTimePickerField
            name="depotDeparture"
            label="Planned Depot Departure DateTime"
            formik
          />
          <VantageDateTimePickerField
            name="actualDispatch"
            label="Actual Dispatch DateTime"
            formik
          />
          <VantageDateTimePickerField
            name="deliverByDateTime"
            label="Deliver By DateTime"
            formik
          />
        </VantageFormFieldsRow>
        <VantageAutocompleteFieldWithListing
          name="customer"
          models="Customer"
          label="Customer"
          placeholder="Enter and select the customer"
          formik
        />
        <VantageTextField
          name="deliveryNoteNumber"
          label="Delivery Note Number"
          placeholder="Enter the delivery note number"
          formik
        />
        <VantageTextField
          name="customerReferenceNumber"
          label="Customer Reference Number"
          placeholder="Enter the customer reference number"
          formik
        />
        <VantageAutocompleteFieldWithListing
          name="transporter"
          label="Transporter"
          models="Transporter"
          placeholder="Enter the transporter"
          formik
        />
        <VantageTextField
          name="primeMover"
          placeholder="Enter the vehicle registration number"
          label="Prime Mover"
          formik
        />
        <VantageFormFieldsRow>
          <VantageAutocompleteFieldWithNodeListing
            name="upliftPoint"
            label="Loading Point"
            type="LOADING"
            formik
          />
          <VantageAutocompleteFieldWithNodeListing
            name="offloadPoint"
            label="Offload Point"
            type="OFFLOADING"
            formik
          />
          <VantageSelectField
            name="status"
            label="Status"
            options={statuses}
            placeholder="Select Status"
            formik
          />
        </VantageFormFieldsRow>
        <VantageAutocompleteFieldWithListing
          name="contract"
          label="Contract"
          models="Contract"
          formik
        />
      </VantageFormContent>
      <VantageFormActions zIndex={500} bgcolor="background.paper">
        <VantageFormSubmit label="Next" />
      </VantageFormActions>
    </VantageForm>
  );
}
