import {type ReactElement} from 'react';

import Tooltip, {type TooltipProps} from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {config} from '../../../config';

// TODO Theming?
export function VantageTooltip({
  children,
  title,
  ...props
}: Omit<TooltipProps, 'children'> & {children: string | ReactElement}) {
  if (title == null) {
    return children;
  }
  return (
    <Tooltip
      arrow
      {...props}
      slotProps={{
        ...props.slotProps,
        tooltip: {
          ...props.slotProps?.tooltip,
          sx: {
            bgcolor: '#292929',
            color: '#fff',
            p: 1,
            maxWidth: config.leftDrawerWidth - 25,
            ...props.slotProps?.tooltip?.sx,
          },
        },
        arrow: {
          ...props.slotProps?.arrow,
          sx: {
            color: '#171717',
            ...props.slotProps?.arrow?.sx,
          },
        },
      }}
      title={
        typeof title === 'string' ? (
          <Typography variant="body2" color="#fff">
            {title}
          </Typography>
        ) : (
          title
        )
      }
    >
      {typeof children === 'string' ? (
        <Typography sx={{width: 'fit-content'}} variant="body2">
          {children}
        </Typography>
      ) : (
        children
      )}
    </Tooltip>
  );
}
