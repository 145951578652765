import {useNavigate} from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type {Order} from '@onroadvantage/onroadvantage-api';

import {routes} from '../../../routes';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import type {VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {VantageTable} from '../../common/components/table/VantageTable';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {useDeleteOrder} from '../hooks/useDeleteOrder';
import {useLoadOrders} from '../hooks/useLoadOrders';

const tableConfig: VantageTableConfig<Order> = {
  columns: [
    {
      name: 'orderNumber',
      label: 'Order Number',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'externalTripNumber',
      label: 'Trip Number',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'status',
      label: 'Status',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'contractId',
      label: 'Contract Id',
      type: 'string',
      hidden: true,
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'contract.code',
      label: 'Contract Code',
      type: 'string',
      getValue: ({contract}) => contract?.code,
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'upliftPointId',
      label: 'Uplift Point Id',
      type: 'string',
      hidden: true,
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'upliftPoint.name',
      label: 'Loading Point',
      type: 'string',
      getValue: ({upliftPoint}) => upliftPoint?.name,
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'offloadPointId',
      label: 'Offload Point Id',
      type: 'string',
      hidden: true,
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'offloadPoint.name',
      label: 'Offload Point',
      type: 'string',
      getValue: ({offloadPoint}) => offloadPoint?.name,
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'customerId',
      label: 'Customer Id',
      type: 'number',
      hidden: true,
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'customer.name',
      label: 'Customer Name',
      type: 'string',
      enableSort: true,
      getValue: ({customer}) => customer?.name,
    },
    {
      name: 'transporterId',
      label: 'Transporter Id',
      type: 'string',
      hidden: true,
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'transporter.name',
      label: 'Transporter',
      type: 'string',
      enableSort: true,
      hidden: true,
      getValue: ({transporter}) => transporter?.name,
    },
    {
      name: 'primeMover',
      label: 'Prime Mover',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'depotDeparture',
      label: 'Planned Depot Departure',
      type: 'datetime',
    },
    {
      name: 'actualDispatch',
      label: 'Actual Dispatch',
      type: 'datetime',
      hidden: true,
    },
  ],
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Order:</Typography>
        <Typography>{row.orderNumber}</Typography>
      </Stack>
    </Stack>
  ),
  enableColumnVisibility: true,
  enableColumnResizing: true,
  enableColumnGrouping: true,
  enableDateRangeToggle: true,
  identifier: 'orders',
};

export default function Orders() {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, loadDataStatus, removeItem, reloadData} = useLoadOrders({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const {deleteStatus, onDelete} = useDeleteOrder({removeItem});

  return (
    <VantageTablePaper>
      <OverlayLoader
        loading={loadDataStatus === 'idle'}
        label="Preparing content..."
      />
      <OverlayLoader
        loading={loadDataStatus === 'loading'}
        label="Loading orders..."
      />
      <OverlayLoader
        loading={loadDataStatus === 'reloading'}
        label="Reloading orders..."
      />
      <OverlayLoader
        loading={deleteStatus === 'deleting'}
        label="Deleting order..."
      />
      <VantageTable
        rowId="id"
        config={tableConfig}
        data={data}
        grouping={[]}
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
        toolbar={{onRefresh: reloadData}}
        actions={{
          onAdd: hasPermissions('order:add')
            ? () => {
                navigate(routes.order.add);
              }
            : undefined,
        }}
        rowActions={{
          onNavigate: ({id}) => {
            if (id != null) {
              navigate(
                routes.replaceParams(routes.order.orderId, {
                  orderId: id,
                }),
              );
            }
          },
          onDelete: hasPermissions('order:delete') ? onDelete : undefined,
        }}
      />
    </VantageTablePaper>
  );
}
