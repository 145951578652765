import {type ComponentType, memo, useCallback} from 'react';

import {
  Table,
  type TableProps,
  VirtualTable,
  type VirtualTableProps,
} from '@devexpress/dx-react-grid-material-ui';
import {styled} from '@mui/material/styles';

import {VantageTableRow, type VantageTableRowProps} from './VantageTableRow';
import {VantageTableSortableRow} from './VantageTableSortableRow';
import {addShouldNotForwardProps} from '../../../helpers/addShouldNotForwardProps';
import {VantageTableCellComponent} from '../styledComponents/VantageTableCellComponent';

type VantageTableBaseProps<TableData extends object> =
  | ({
      rowComponent?: ComponentType<Table.DataRowProps>;
      backgroundColor: string | undefined;
      fitHeight: true;
    } & VantageTableRowProps<TableData> &
      TableProps)
  | ({
      rowComponent?: ComponentType<Table.DataRowProps>;
      backgroundColor: string | undefined;
      fitHeight?: false;
    } & VantageTableRowProps<TableData> &
      VirtualTableProps);

function VantageTableBaseComponent<TableData extends object>({
  rowComponent,
  backgroundColor,
  onDragStart,
  onRightClick,
  config,
  fitHeight,
  ...props
}: VantageTableBaseProps<TableData>) {
  const renderRowComponent = useCallback<ComponentType<Table.DataRowProps>>(
    ({children, ...rowProps}) =>
      config.enableRowSorting === true ? (
        <VantageTableSortableRow
          {...rowProps}
          config={config}
          onDragStart={onDragStart}
          onRightClick={onRightClick}
        >
          {children}
        </VantageTableSortableRow>
      ) : (
        <VantageTableRow
          {...rowProps}
          config={config}
          onDragStart={onDragStart}
          onRightClick={onRightClick}
        >
          {children}
        </VantageTableRow>
      ),
    [config, onDragStart, onRightClick],
  );

  const renderContainerComponent = useCallback(
    (containerProps: object) => (
      <VantageTableContainerComponent
        {...containerProps}
        backgroundColor={backgroundColor}
      />
    ),
    [backgroundColor],
  );
  const renderVirtualContainerComponent = useCallback(
    (containerProps: object) => (
      <VantageVirtualTableContainerComponent
        {...containerProps}
        backgroundColor={backgroundColor}
      />
    ),
    [backgroundColor],
  );
  const renderHeaderComponent = useCallback(
    (headerProps: object) => (
      <VantageTableHeadComponent
        {...headerProps}
        backgroundColor={backgroundColor}
      />
    ),
    [backgroundColor],
  );
  const renderTableComponent = useCallback(
    (tableProps: object) =>
      config.enableRowSorting === true ? (
        <Table.Table {...tableProps} style={{overflow: 'hidden'}} />
      ) : (
        <Table.Table {...tableProps} />
      ),
    [config],
  );

  if (fitHeight === true) {
    return (
      <Table
        containerComponent={renderContainerComponent}
        cellComponent={VantageTableCellComponent}
        headComponent={renderHeaderComponent}
        rowComponent={rowComponent ?? renderRowComponent}
        tableComponent={renderTableComponent}
        {...props}
      />
    );
  }

  return (
    <VirtualTable
      containerComponent={renderVirtualContainerComponent}
      cellComponent={VantageTableCellComponent}
      headComponent={renderHeaderComponent}
      rowComponent={rowComponent ?? renderRowComponent}
      estimatedRowHeight={52}
      tableComponent={renderTableComponent}
      {...props}
    />
  );
}

export const VantageTableBase = memo(
  VantageTableBaseComponent,
) as typeof VantageTableBaseComponent;

const VantageTableHeadComponent = styled(VirtualTable.TableHead, {
  shouldForwardProp: addShouldNotForwardProps('backgroundColor'),
})<{backgroundColor: string | undefined}>(({theme, backgroundColor}) => ({
  backgroundColor: backgroundColor ?? theme.palette.background.paper,
}));
const VantageTableContainerComponent = styled(Table.Container, {
  shouldForwardProp: addShouldNotForwardProps('backgroundColor'),
})<{backgroundColor: string | undefined}>(({theme, backgroundColor}) => ({
  // borderRadius: theme.shape.borderRadius,
  overflowX: 'hidden',
  backgroundColor: backgroundColor ?? theme.palette.background.paper,
  // '&::-webkit-scrollbar-track': {
  //   backgroundColor: backgroundColor ?? theme.palette.background.paper,
  // },
  // '&::-webkit-scrollbar-thumb': {
  //   backgroundColor: theme.palette.background.default,
  // },
  scrollbarColor: 'blue !important',
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'green !important',
  },
}));

const VantageVirtualTableContainerComponent = styled(VirtualTable.Container, {
  shouldForwardProp: addShouldNotForwardProps('backgroundColor'),
})<{backgroundColor: string | undefined}>(({theme, backgroundColor}) => ({
  // borderRadius: theme.shape.borderRadius,
  overflowX: 'hidden',
  backgroundColor: backgroundColor ?? theme.palette.background.paper,
  // '&::-webkit-scrollbar-track': {
  //   backgroundColor: backgroundColor ?? theme.palette.background.paper,
  // },
  // '&::-webkit-scrollbar-thumb': {
  //   backgroundColor: theme.palette.background.default,
  // },
  scrollbarColor: 'blue !important',
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'green !important',
  },
}));
