import {lazy, Suspense} from 'react';
import {useNavigate} from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorIcon from '@mui/icons-material/ErrorRounded';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {keyframes, styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {AuthCard} from './AuthCard';
import {AuthenticationLogo} from './AuthenticationLogo';
import {routes} from '../../../routes';

const LazyAppShellAnimatedBackground = lazy(
  async () =>
    import('../../common/components/appShell/AppShellAnimatedBackground'),
);

interface AuthLoaderProps {
  message: string;
  status: 'loading' | 'success' | 'error';
}

export function AuthStateMessage({message, status}: AuthLoaderProps) {
  const navigate = useNavigate();
  let color: string = 'text.primary';
  switch (status) {
    case 'success':
      color = 'success';
      break;
    case 'error':
      color = 'error';
      break;
    case 'loading':
    default:
      color = 'text.secondary';
  }
  return (
    <Stack justifyContent="center" alignItems="center" flex={1}>
      <Suspense fallback={null}>
        <LazyAppShellAnimatedBackground />
      </Suspense>
      <AuthCard>
        <AuthenticationLogo />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          mt={1}
          mb={1.5}
        >
          <LoadingTypography
            variant="h6"
            color={color}
            textAlign="center"
            fontWeight={600}
          >
            {message}
            {status === 'loading' && (
              <>
                <span />
                <span />
                <span />
              </>
            )}
          </LoadingTypography>
          {status === 'error' && <ErrorIcon fontSize="large" color="error" />}
          {status === 'success' && (
            <CheckCircleIcon fontSize="large" color="success" />
          )}
        </Stack>
        {status === 'error' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate(routes.auth.login);
            }}
          >
            Go to Login
          </Button>
        )}
        {status === 'success' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate(routes.home);
            }}
          >
            Go Home
          </Button>
        )}
      </AuthCard>
    </Stack>
  );
}

const reloadingBounceAnimation = keyframes`
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-4px);
  }
`;
const LoadingTypography = styled(Typography)(({theme}) => ({
  '& > span': {
    display: 'inline-block',
    animation: `${reloadingBounceAnimation} 1s infinite`,
    background: theme.palette.text.secondary,
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    marginInline: '2px',

    '&:nth-child(1)': {
      animationDelay: '0.1s',
      marginLeft: '6px',
    },

    '&:nth-child(2)': {
      animationDelay: '0.2s',
    },

    '&:nth-child(3)': {
      animationDelay: '0.3s',
    },
  },
}));
