import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlayRounded';

import {type HomeMenuItemCard} from './homeMenuItems';
import {routes} from '../../../routes';

export const executionHomeMenuItems: HomeMenuItemCard = {
  title: 'Execution',
  icon: <PlaylistPlayIcon color="action" />,
  menuItems: [
    {
      label: 'Trips',
      path: routes.trip.list,
      permissions: ['trip:list'],
    },
    {
      label: 'Orders',
      path: routes.order.list,
      permissions: ['order:list'],
    },
    {
      label: 'Tasks',
      path: routes.task.list,
      permissions: ['task:list'],
    },
    {
      label: 'Workflows',
      path: routes.workflow.list,
      permissions: ['workflow:list'],
    },
  ],
};
