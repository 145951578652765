import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';

import {config} from '../../../../../config';

export const VantageTablePaper = styled(Paper)(({theme}) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  flex: 1,
  flexGrow: 1,
  position: 'relative',
  // overflow: 'hidden',
  // height: '100%',
  margin: theme.spacing(config.theme.gutterSpacing),
}));
