import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {unstable_batchedUpdates} from 'react-dom';

import type {Filter} from '@devexpress/dx-react-grid';
import {debounce} from '@mui/material/utils';

import {type VantageTableFiltering} from '../components/table/VantageTable';

interface UseFilteringParams {
  setCurrentPage: Dispatch<SetStateAction<number>> | null;
}

export const useFiltering = ({setCurrentPage}: UseFilteringParams) => {
  const [filters, setFilters] = useState<Filter[]>([]);

  return {
    tableFiltering: useMemo(
      () =>
        ({
          filters,
          onFiltersChange: debounce((newFilters: Filter[]) => {
            unstable_batchedUpdates(() => {
              if (setCurrentPage != null) {
                setCurrentPage(1);
              }
              setFilters(
                newFilters.map((newFilter) => {
                  const splitColumn = newFilter.columnName.split('.');
                  let {columnName} = newFilter;
                  if (splitColumn.length > 1) {
                    columnName = splitColumn
                      .map((word, index) =>
                        index !== 0
                          ? word[0].toUpperCase() + word.slice(1)
                          : word,
                      )
                      .join('');
                  }
                  return {...newFilter, columnName};
                }),
              );
            });
          }, 500),
        }) satisfies VantageTableFiltering,
      [filters, setCurrentPage],
    ),
    getFilter: useCallback(
      (columnName: string) =>
        filters.find((f) => f.columnName === columnName)?.value,
      [filters],
    ),
  };
};

export type GetFilter = ReturnType<typeof useFiltering>['getFilter'];
