import {
  type ElementType,
  memo,
  type SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {type AutocompleteInputChangeReason} from '@mui/base/useAutocomplete';
import MuiAutocomplete, {
  type AutocompleteProps,
  type AutocompleteRenderInputParams,
  type AutocompleteValue,
} from '@mui/material/Autocomplete';
import {type ChipTypeMap} from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import {useTheme} from '@mui/material/styles';
import TextField, {type TextFieldProps} from '@mui/material/TextField';
import {type UseAutocompleteProps} from '@mui/material/useAutocomplete';
import {useFormikContext} from 'formik';

import {useFieldDisabled} from './hooks/useFieldDisabled';
import {useFieldError} from './hooks/useFieldError';
import {isObjectWithKeys} from '../../helpers/unknownValueTypeChecks';

export interface AutocompleteOption {
  value?: string | number | undefined;
  label?: string | undefined;
  metaData?: string;
}

export type VantageAutocompleteFieldProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> =
  | ({formik: true} & VantageAutocompleteFieldFormikProps<
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >)
  | ({formik?: false} & VantageAutocompleteFieldBaseProps<
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >);

export const VantageAutocompleteField = memo(function VantageAutocompleteField<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>({
  formik,
  ...props
}: VantageAutocompleteFieldProps<
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) {
  if (formik === true) {
    return <VantageAutocompleteFieldFormik {...props} />;
  }

  return <VantageAutocompleteFieldBase {...props} />;
});

export type VantageAutocompleteFieldFormikProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = VantageAutocompleteFieldBaseProps<
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>;

function VantageAutocompleteFieldFormik<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>({
  name,
  disabled = false,
  onInputChange,
  ...props
}: VantageAutocompleteFieldFormikProps<
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) {
  const fieldDisabled = useFieldDisabled(disabled);
  const fieldError = useFieldError(name);
  const {getFieldMeta, getFieldHelpers, handleBlur} = useFormikContext();
  const {value} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  const handleChange = useCallback<
    VantageAutocompleteFieldOnChange<Multiple, DisableClearable, FreeSolo>
  >(
    async (_event, newValue) => {
      await setValue(newValue);
    },
    [setValue],
  );

  const handleInputChange = useCallback<VantageAutocompleteFieldOnInputChange>(
    (event, newValue, reason) => {
      setInputValue(newValue);
      if (onInputChange != null) {
        onInputChange(event, newValue, reason);
      }
    },
    [onInputChange],
  );

  const parsedValue = useMemo(() => {
    if (props.multiple === true && value == null) {
      return [] as unknown as AutocompleteValue<
        AutocompleteOption,
        Multiple,
        DisableClearable,
        FreeSolo
      >;
    }
    if (
      props.multiple !== true &&
      ((isObjectWithKeys(value, 'value') && value.value == null) ||
        value == null)
    ) {
      return {value: 0} as unknown as AutocompleteValue<
        AutocompleteOption,
        Multiple,
        DisableClearable,
        FreeSolo
      >;
    }
    return value as AutocompleteValue<
      AutocompleteOption,
      Multiple,
      DisableClearable,
      FreeSolo
    >;
  }, [props.multiple, value]);

  return (
    <VantageAutocompleteFieldBase
      error={fieldError != null}
      {...props}
      name={name}
      disabled={fieldDisabled}
      onBlur={handleBlur}
      onChange={handleChange}
      onInputChange={handleInputChange}
      value={parsedValue}
      inputValue={inputValue}
      TextFieldProps={{helperText: fieldError, ...props.TextFieldProps}}
    />
  );
}

export type VantageAutocompleteFieldOnChange<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = NonNullable<
  UseAutocompleteProps<
    AutocompleteOption,
    Multiple,
    DisableClearable,
    FreeSolo
  >['onChange']
>;

export type VantageAutocompleteFieldOnInputChange = (
  event: SyntheticEvent,
  value: string,
  reason: AutocompleteInputChangeReason,
) => void;

const isOptionEqualToValue = (
  option: AutocompleteOption,
  val: AutocompleteOption,
) => {
  if (Object.keys(val).length === 0) {
    return false;
  }
  if (val.value === 0 || val.value == null || !('value' in val)) {
    return 'label' in val && option.label === val.label;
  }
  return option.value === val.value;
};

export interface VantageAutocompleteFieldBaseProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<
      AutocompleteProps<
        AutocompleteOption,
        Multiple,
        DisableClearable,
        FreeSolo,
        ChipComponent
      >,
      'renderInput' | 'onChange'
    >,
    Pick<TextFieldProps, 'error' | 'label' | 'placeholder'> {
  name: string;
  TextFieldProps?: TextFieldProps;
  onChange?: VantageAutocompleteFieldOnChange<
    Multiple,
    DisableClearable,
    FreeSolo
  >;
}

const getOptionLabel = (option: unknown) => {
  if (isObjectWithKeys(option, 'label') && typeof option.label === 'string') {
    return option.label;
  }
  if (typeof option === 'string') {
    return option;
  }
  return '';
};

const getOptionKey = (option: unknown) => {
  if (isObjectWithKeys(option, 'value') && typeof option.value === 'number') {
    return option.value.toString() ?? '';
  }
  if (isObjectWithKeys(option, 'label') && typeof option.label === 'string') {
    return option.label;
  }
  if (typeof option === 'string') {
    return option;
  }
  return '';
};

function VantageAutocompleteFieldBase<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>({
  name,
  placeholder,
  error,
  label,
  TextFieldProps,
  loading = false,
  ...props
}: VantageAutocompleteFieldBaseProps<
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) {
  const theme = useTheme();
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <TextField
          label={label}
          suppressContentEditableWarning
          suppressHydrationWarning
          name={name}
          error={error}
          placeholder={placeholder}
          {...params}
          {...TextFieldProps}
          InputProps={{
            ...params.InputProps,
            ...TextFieldProps?.InputProps,
            style: {
              ...TextFieldProps?.InputProps?.style,
              borderRadius:
                props.multiple === true ? theme.shape.borderRadius : undefined,
            },
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={14} />
                ) : null}
                {TextFieldProps?.InputProps?.endAdornment ??
                  params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      );
    },
    [
      TextFieldProps,
      error,
      label,
      loading,
      name,
      placeholder,
      props.multiple,
      theme.shape.borderRadius,
    ],
  );

  return (
    <MuiAutocomplete<
      AutocompleteOption,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      getOptionKey={getOptionKey}
      renderInput={renderInput}
      id={name}
      loading={loading}
      fullWidth
      {...props}
    />
  );
}
