import AssignmentIcon from '@mui/icons-material/AssignmentRounded';

import {type HomeMenuItemCard} from './homeMenuItems';
import {routes} from '../../../routes';

export const planningHomeMenuItems: HomeMenuItemCard = {
  title: 'Planning',
  icon: <AssignmentIcon color="action" />,
  menuItems: [
    // {
    //   label: 'Optimiser',
    //   path: routes.planningSolution.list,
    //   permissions: ['planningSolution:list'],
    // },
    {
      label: 'Planning Board',
      path: routes.planningBoard,
      permissions: ['planningBoard:view'],
    },
    {
      label: 'Master Routes',
      path: routes.masterRoute.list,
      permissions: ['masterRoute:list'],
    },
    {
      label: 'Vehicle Booking Availability',
      path: routes.vehicleBookingAvailability,
      permissions: ['booking:list'],
    },
  ],
};
