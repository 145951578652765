import {type ReactNode, useCallback} from 'react';

import Button, {type ButtonProps} from '@mui/material/Button';
import {useFormikContext} from 'formik';

import {type Permission} from '../../../../permissions';

interface ResetProps extends Omit<ButtonProps, 'title'> {
  onReset?: () => void;
  label?: ReactNode;
  disableIsDirty?: boolean;
  loading?: boolean;
  permission?: Permission;
  enabled?: boolean;
}

export function VantageFormReset({
  onReset,
  label = 'Reset',
  disableIsDirty = false,
  loading = false,
  disabled = false,
  ...props
}: ResetProps) {
  const {dirty, isSubmitting, status, resetForm} = useFormikContext();
  const handleReset = useCallback(() => {
    resetForm();
    onReset?.();
  }, [onReset, resetForm]);
  return (
    <Button
      variant="outlined"
      data-cy="reset"
      fullWidth={false}
      aria-label="Reset form button"
      {...props}
      disabled={
        status === 'unauthorized' ||
        status === 'disabled' ||
        (!disableIsDirty && !dirty) ||
        disabled ||
        loading ||
        isSubmitting
      }
      onClick={handleReset}
      type="button"
    >
      {label}
    </Button>
  );
}
