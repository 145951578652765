import {type ReactNode, type SyntheticEvent, useMemo} from 'react';

import {type Table} from '@devexpress/dx-react-grid-material-ui';
import {type AutocompleteInputChangeReason} from '@mui/material/Autocomplete';
import {type Listing} from '@onroadvantage/onroadvantage-api';

interface VantageTableColumnBase<TableData extends object> {
  name: keyof TableData | string;
  label: string;
  columnExtensions?: Omit<Table.ColumnExtension, 'columnName'>;
  align?: 'left' | 'right' | 'center';
  width?: string | number;
  word?: string | number;
  hidden?: boolean;
  enableFilter?: boolean;
  enableSort?: boolean;
  enableGrouping?: boolean;
  enableEdit?: boolean | ((row: TableData) => boolean);
  enableAdd?: boolean | ((row: TableData) => boolean);
  getDisplayValue?: (row: TableData) => ReactNode | null | undefined;
}

export type VantageTableColumn<TableData extends object> =
  VantageTableColumnBase<TableData> &
    (
      | {
          type: 'unknown';
          getValue?: (row: TableData) => unknown;
        }
      | {
          type: 'string';
          getValue?: (row: TableData) => ReactNode | null | undefined;
        }
      | {
          type: 'number';
          getValue?: (row: TableData) => string | number | null | undefined;
        }
      | {
          type: 'date';
          getValue?: (row: TableData) => Date | string | null | undefined;
        }
      | ({
          type: 'autocomplete';
          limitTags?: number;
          getValue?: (
            row: TableData,
          ) =>
            | {label?: string | undefined; value?: number | undefined}
            | null
            | undefined;
        } & (
          | {models: Set<string> | string}
          | {
              options: Listing[];
              onInputChange?: (
                _event: SyntheticEvent,
                value: string,
                reason: AutocompleteInputChangeReason,
              ) => void;
            }
        ))
      | ({
          type: 'autocompleteMulti';
          limitTags?: number;
          getValue?: (
            row: TableData,
          ) =>
            | Array<{label: string | undefined; value: number | undefined}>
            | null
            | undefined;
        } & (
          | {models: Set<string> | string}
          | {
              options: Listing[];
              onInputChange?: (
                _event: SyntheticEvent,
                value: string,
                reason: AutocompleteInputChangeReason,
              ) => void;
            }
        ))

      // | {
      //     type: 'autocompleteMulti';
      //     options: Listing[];
      //     getValue?: (row: TableData) => Listing[];
      //   }
      | {
          type: 'datetime';
          getValue?: (row: TableData) => Date | string | null | undefined;
        }
      | {
          type: 'time';
          getValue?: (row: TableData) => Date | string | null | undefined;
        }
      | {
          type: 'progress';
          getValue?: (row: TableData) => number;
        }
      | {
          type: 'link';
          getValue?: (row: TableData) => string | null | undefined;
        }
      | {
          type: 'boolean';
          getValue?: (row: TableData) => boolean;
        }
      | {
          type: 'custom';
          getCellValue?: (row: TableData) => ReactNode | null | undefined;
          getValue?: (row: TableData) => unknown | null | undefined;
        }
    );

export type VantageTableDeleteMessage<TableData extends object> = (
  row: TableData,
) => ReactNode;

export type VantageTableConfig<TableData extends object> = {
  columns: Array<VantageTableColumn<TableData>>;
  deleteMessage?: VantageTableDeleteMessage<TableData>;
  getRowClass?: (row: TableData) => string;
  enableColumnResizing?: boolean;
  enableColumnVisibility?: boolean;
  enableColumnGrouping?: boolean;
  enableRowSorting?: boolean;
  enableRowDownload?: (row: TableData) => boolean;
  enableDateRangeToggle?: boolean;
  downloadIcon?: ReactNode;
  selection?: {
    enableSelectAll?: boolean;
    getSelectableRow?: (row: TableData) => boolean;
    selectionType?: 'checkbox' | 'row' | 'radio';
  };
  backgroundColor?: string;
  fitHeight?: boolean;
} & (
  | {identifier: string; enableColumnVisibility: true}
  | {enableColumnVisibility?: false}
) &
  (
    | {identifier: string; enableColumnGrouping: true}
    | {enableColumnGrouping?: false}
  );

export const useVantageTableConfig = <TableData extends object>(
  config: VantageTableConfig<TableData>,
) => {
  return useMemo(() => {
    const actionColumn = {
      columnName: 'actions',
      filteringEnabled: false,
      sortingEnabled: false,
      groupingEnabled: false,
    };
    const groupMap = config.columns.map((i) => ({
      columnName: i.name as string,
      groupingEnabled: i.enableGrouping ?? false,
    }));
    const filterMap = config.columns.map((i) => ({
      columnName: i.name as string,
      filteringEnabled: i.enableFilter ?? false,
    }));
    const sortMap = config.columns.map((i) => ({
      columnName: i.name as string,
      sortingEnabled: i.enableSort ?? true,
    }));

    return {
      columns: config.columns.map((column) => ({
        name: column.name as string,
        title: column.label,
        getCellValue:
          'getCellValue' in column ? column.getCellValue : undefined,
      })),
      resizeDefaultWidths: config.columns.map((column) => ({
        columnName: column.name as string,
        width: column.columnExtensions?.width ?? 'auto',
      })),
      groupColumns: [actionColumn, ...groupMap],
      filterColumns: [actionColumn, ...filterMap],
      sortColumns: [actionColumn, ...sortMap],
      hiddenColumns: config.columns
        .filter((column) => column.hidden)
        .map(({name}) => name as string),
      stringColumns: config.columns
        .filter((column) => column.type === 'string')
        .map(({name}) => name as string),
      autocompleteMulti: config.columns
        .filter(
          (column) =>
            column.type === 'autocompleteMulti' ||
            column.type === 'autocomplete',
        )
        .map(({name}) => name as string),
      numberColumns: config.columns
        .filter((column) => column.type === 'number')
        .map(({name}) => name as string),
      dateColumns: config.columns
        .filter((column) => column.type === 'date')
        .map(({name}) => name as string),
      datetimeColumns: config.columns
        .filter((column) => column.type === 'datetime')
        .map(({name}) => name as string),
      progressColumns: config.columns
        .filter((column) => column.type === 'progress')
        .map(({name}) => name as string),
      linkColumns: config.columns
        .filter((column) => column.type === 'link')
        .map(({name}) => name as string),
      booleanColumns: config.columns
        .filter((column) => column.type === 'boolean')
        .map(({name}) => name as string),
      customColumns: config.columns.filter(
        (column) => column.type === 'custom',
      ),
      columnExtensions:
        config.columns
          .filter(({columnExtensions}) => columnExtensions != null)
          .map(({name, columnExtensions}) => ({
            columnName: name,
            width: 'auto',
            align: 'left',
            wordWrapEnabled: false,
            ...columnExtensions,
          })) ?? [],
    };
  }, [config.columns]);
};
