import {useMemo} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';
import Box from '@mui/material/Box';
import CircularProgress, {
  type CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export const getPercentage = (value?: string | number): number => {
  const progressInt =
    typeof value === 'string' ? parseInt(value, 10) : value ?? 0;
  return Math.round(Math.min(Math.max(progressInt * 100, 0), 100));
};

interface CircularProgressWithLabelProps extends CircularProgressProps {
  value: number;
}

function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
  return (
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
      <CircularProgress size={45} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          // eslint-disable-next-line react/destructuring-assignment
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export function ProgressTypeProviderFormatter({
  value,
}: DataTypeProvider.ValueFormatterProps) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const progress = useMemo(() => getPercentage(value) ?? 0, [value]);

  let color: CircularProgressProps['color'];

  switch (true) {
    case progress < 25:
      color = 'error';
      break;
    case progress < 50:
      color = 'warning';
      break;
    case progress < 75:
      color = 'info';
      break;
    case progress < 100:
      color = 'secondary';
      break;
    case progress === 100:
      color = 'primary';
      break;
    default:
      color = 'inherit';
  }

  return <CircularProgressWithLabel value={progress} color={color} />;
}
