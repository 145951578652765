import {
  type ComponentType,
  type CSSProperties,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
  useCallback,
  useState,
} from 'react';

import Button, {type ButtonProps} from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle, {type DialogTitleProps} from '@mui/material/DialogTitle';
import Icon from '@mui/material/Icon';
import IconButton, {type IconButtonProps} from '@mui/material/IconButton';
import {type ModalProps} from '@mui/material/Modal';
import {type PaperProps} from '@mui/material/Paper';

import {useVantageDialogButtonStyles} from './VantageDialogButton.style';
import {isString} from '../../helpers/unknownValueTypeChecks';
import {
  type MuiButtonColorTypes,
  type MuiButtonVariantTypes,
  type SxStyleType,
} from '../../types/mui';

export type ReactMouseEvent = MouseEvent<HTMLButtonElement>;

interface VantageDialogStyleClasses {
  acceptButton?: string;
  actions?: string;
  content?: string;
  declineButton?: string;
  dialog?: string;
  title?: string;
}

interface VantageDialogActionProps {
  color?: MuiButtonColorTypes;
  startIcon?: ReactNode;
  disabled?: boolean;
  onClick?: (e: ReactMouseEvent) => void;
  sx?: SxStyleType;
  style?: CSSProperties;
  title?: string;
  type?: 'button' | 'submit';
  variant?: MuiButtonVariantTypes;
}

interface DialogOptions {
  closeDialog?: 'before' | 'after';
}

export interface VantageDialogButtonProps {
  acceptProps?: VantageDialogActionProps;
  acceptTitle?: string;
  actionsSx?: SxStyleType;
  actionsStyle?: CSSProperties;
  contentSx?: SxStyleType;
  dataCy?: string;
  declineProps?: VantageDialogActionProps;
  declineTitle?: string;
  dialogActionOptions?: DialogOptions;
  DialogTitleProps?: DialogTitleProps;
  dialogSx?: SxStyleType;
  dialogTitle?: string | ReactNode;
  disableAccept?: boolean;
  disableDecline?: boolean;
  disableTitle?: boolean;
  enableDividers?: boolean;
  fullScreen?: boolean;
  fullWidth?: boolean;
  iconButton?: boolean;
  IconButtonProps?: IconButtonProps;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onAccept?: (e: ReactMouseEvent) => void;
  onClose?: ModalProps['onClose'];
  onDecline?: (e: ReactMouseEvent) => void;
  PaperComponent?: ComponentType<PaperProps>;
  reverseActions?: boolean;
  styles?: VantageDialogStyleClasses;
  sx?: SxStyleType;
  title: string | ReactElement;
  titleSx?: SxStyleType;
}

export function VantageDialogButton({
  children,
  title,
  onClick,
  acceptProps,
  declineProps,
  onAccept,
  acceptTitle,
  actionsSx,
  actionsStyle,
  contentSx,
  dataCy,
  onDecline,
  declineTitle,
  dialogActionOptions,
  DialogTitleProps,
  dialogSx,
  dialogTitle,
  disableAccept,
  disableDecline,
  disableTitle,
  enableDividers,
  fullScreen,
  fullWidth,
  iconButton,
  IconButtonProps,
  maxWidth,
  onClose,
  PaperComponent,
  reverseActions,
  styles,
  sx,
  titleSx,
  ...props
}: VantageDialogButtonProps & Omit<ButtonProps, 'title'>) {
  const classes = useVantageDialogButtonStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      setOpen(true);
      if (onClick != null) {
        onClick(e);
      }
    },
    [setOpen, onClick],
  );

  const handleClose = useCallback(
    (
      event: Record<string, unknown>,
      reason: 'backdropClick' | 'escapeKeyDown',
    ) => {
      setOpen(false);
      if (onClose != null) onClose(event, reason);
    },
    [onClose],
  );

  const handleClick = useCallback(
    (action: 'Accept' | 'Decline') => async (e: unknown) => {
      if (
        dialogActionOptions?.closeDialog === 'before' ||
        dialogActionOptions == null
      ) {
        setOpen(false);
      }
      if (action === 'Accept' && onAccept != null) {
        onAccept(e as ReactMouseEvent);
      } else if (action === 'Decline' && onDecline != null) {
        onDecline(e as ReactMouseEvent);
      }
      if (dialogActionOptions?.closeDialog === 'after') {
        setOpen(false);
      }
    },
    [dialogActionOptions, onAccept, onDecline],
  );

  return (
    <>
      {iconButton != null ? (
        <IconButton
          {...IconButtonProps}
          className={IconButtonProps?.className ?? props.className}
          size={IconButtonProps?.size}
          disabled={props.disabled}
          color={props.color}
          onClick={handleOpen}
          type="button"
          data-cy={dataCy}
        >
          {title ?? <Icon />}
        </IconButton>
      ) : (
        <Button
          {...props}
          color={props.color ?? 'primary'}
          onClick={handleOpen}
          variant={props.variant}
          type="button"
          data-cy={dataCy}
        >
          {title ?? 'button'}
        </Button>
      )}
      <Dialog
        className={styles?.dialog ?? classes.dialog}
        sx={dialogSx}
        open={open}
        onClose={handleClose}
        maxWidth={maxWidth}
        aria-label={`${isString(title) ? title : ''} - Vantage Dialog`}
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        PaperComponent={PaperComponent}
      >
        {disableTitle !== true && (
          <DialogTitle
            className={styles?.title ?? classes.title}
            sx={titleSx}
            aria-label={`${
              isString(title) ? title : ''
            } - Vantage Dialog Title`}
            {...DialogTitleProps}
          >
            {dialogTitle ?? 'dialog'}
          </DialogTitle>
        )}
        {children != null && (
          <DialogContent
            className={styles?.content}
            sx={contentSx}
            dividers={enableDividers}
            aria-label={`${
              isString(title) ? title : ''
            } - Vantage Dialog Content`}
          >
            {children}
          </DialogContent>
        )}
        <DialogActions
          className={styles?.actions ?? classes.actions}
          sx={{
            ...(reverseActions === true && {flexDirection: 'row-reverse'}),
            ...actionsSx,
          }}
          style={actionsStyle}
          aria-label={`${
            isString(title) ? title : ''
          } - Vantage Dialog Actions`}
        >
          {disableAccept !== true && (
            <Button
              type={acceptProps?.type ?? 'button'}
              disabled={acceptProps?.disabled}
              onClick={handleClick('Accept')}
              color={acceptProps?.color ?? 'primary'}
              startIcon={acceptProps?.startIcon}
              variant={acceptProps?.variant}
              className={styles?.acceptButton ?? classes.acceptButton}
              sx={acceptProps?.sx}
              data-cy="VantageDialogAccept"
            >
              {acceptTitle ?? 'Yes'}
            </Button>
          )}
          {disableDecline !== true && (
            <Button
              type={declineProps?.type ?? 'button'}
              disabled={declineProps?.disabled}
              onClick={handleClick('Decline')}
              color={declineProps?.color ?? 'inherit'}
              className={styles?.declineButton ?? classes.declineButton}
              sx={declineProps?.sx}
              variant={declineProps?.variant}
              data-cy="VantageDialogDecline"
            >
              {declineTitle ?? 'No'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
