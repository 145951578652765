import type {Sorting} from '@devexpress/dx-react-grid';

export const getStringFromSorting = (sorting: Sorting[] | null | undefined) => {
  if (sorting == null) {
    return null;
  }

  let sortString = '';
  sorting.forEach((si, index) => {
    if (index !== 0) {
      sortString += ',';
    }
    sortString += `${si.direction === 'desc' ? '-' : ''}${si.columnName}`;
  });

  return sortString;
};
