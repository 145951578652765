import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import {type Listing, type OrderPost} from '@onroadvantage/onroadvantage-api';

import {type OrderAddFormValues} from './OrderAddForm';
import {type PlanningOrderAddFormValues} from './PlanningOrderAddForm';
import {orderApi, planningOrderApi, orderLineApi} from '../../../api';
import {routes} from '../../../routes';
import type {VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {
  VantageTable,
  type VantageTableOnInlineAdd,
} from '../../common/components/table/VantageTable';
import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';
import {useUserStore} from '../../common/stores/userStore';
// import { useAddPurchaseOrder, type UseAddPurchaseOrderParams} from '../hooks/useAddPurchaseOrder';

export interface OrderLineAdd {
  id: number;
  product: Listing;
  quantity: number;
}

const config: VantageTableConfig<OrderLineAdd> = {
  columns: [
    {
      name: 'product',
      label: 'Product Name',
      type: 'autocomplete',
      models: 'Product',
      enableAdd: true,
      getDisplayValue: (row) => row.product?.label,
    },
    {
      name: 'quantity',
      label: 'Planned Quantity',
      type: 'number',
      enableAdd: true,
    },
  ],
};

interface OrderLinesAddProps {
  // "extends UseAddPurchaseOrderParams" remember to add this back
  // onSubmitOrderLines: (lines: OrderLineAdd[]) => void;
  onPreviousStep: () => void;
  orderFormValues: OrderAddFormValues | null; // should be removed when purchase order step is added
  planningOrderFormValues: PlanningOrderAddFormValues | null; // should be removed when purchase order step is added
}

export function OrderLinesAdd({
  // onSubmitOrderLines,
  onPreviousStep,
  orderFormValues, // remove when purchase order
  planningOrderFormValues, // remove when purchase order
}: OrderLinesAddProps) {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const [lines, setLines] = useState<OrderLineAdd[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onInlineAdd: VantageTableOnInlineAdd = async (values) => {
    try {
      if (
        !isObjectWithKeys(values, 'product') ||
        !isObjectWithKeys(values.product, 'value') ||
        typeof values.product.value !== 'number'
      ) {
        toast.warning('Product is required');
        return 'catch';
      }
      if (
        !isObjectWithKeys(values, 'quantity') ||
        values.quantity == null ||
        Number.isNaN(parseFloat(String(values.quantity) ?? '0'))
      ) {
        toast.warning('Planned quantity is required');
        return 'catch';
      }

      setLines((prev) => [
        ...prev,
        {
          ...(values as OrderLineAdd),
          quantity: parseFloat(String(values.quantity) ?? '0'),
          id: (values.product as Listing)?.value ?? 0,
        } satisfies OrderLineAdd,
      ]);
      return 'success';
    } catch {
      return 'catch';
    }
  };

  const onDelete = async (row: OrderLineAdd) => {
    setLines((prev) => prev.filter((line) => line.id !== row.id));
  };

  // TEMPORARY SUBMIT FUNCTION
  const onSubmit = async () => {
    let currentOrderId;
    setIsSubmitting(true);
    if (orderFormValues != null) {
      const requestOrderAddBody: OrderPost = {
        orderNumber: orderFormValues.orderNumber,
        actualDispatch: orderFormValues.actualDispatch ?? new Date(),
        customerReferenceNumber: orderFormValues.customerReferenceNumber,
        deliveryNoteNumber: orderFormValues.deliveryNoteNumber,
        deliverByDateTime: orderFormValues.deliverByDateTime ?? new Date(),
        depotDeparture: orderFormValues.depotDeparture,
        status: orderFormValues.status,
        primeMover: orderFormValues.primeMover,
        contractId: orderFormValues.contract?.value,
        customerId: orderFormValues.customer?.value,
        offloadPointId: orderFormValues.offloadPoint?.value,
        transporterId: orderFormValues.transporter?.value,
        upliftPointId: orderFormValues.upliftPoint?.value,
      };
      const orderResponse = await orderApi.apiOrderPost({
        body: requestOrderAddBody,
      });
      currentOrderId = orderResponse.id;

      await handleLinesRequest(currentOrderId);
    }

    if (planningOrderFormValues != null) {
      const contractCodeLabel = planningOrderFormValues.contractCode?.label;

      let deliveryExternalReferenceCode: string | undefined;
      try {
        deliveryExternalReferenceCode = JSON.parse(
          planningOrderFormValues.deliveryExternalReference.metaData ?? '{}',
        )?.code;
      } catch {
        deliveryExternalReferenceCode =
          planningOrderFormValues.deliveryExternalReference?.label ?? '';
      }
      let collectionExternalReferenceCode: string | undefined;
      try {
        collectionExternalReferenceCode = JSON.parse(
          planningOrderFormValues.collectionExternalReference.metaData ?? '{}',
        )?.code;
      } catch {
        collectionExternalReferenceCode =
          planningOrderFormValues.collectionExternalReference?.label ?? '';
      }

      const contractCode = contractCodeLabel?.split('-')[0]?.trim() ?? '';
      const skillLabels = planningOrderFormValues.skill.map(
        (skill) => skill.label,
      );
      const skillString = skillLabels.join(', ');

      const requestPlanningOrderAddBody = {
        shipmentNumber: planningOrderFormValues.shipmentNumber,
        upliftPointDuration: planningOrderFormValues.upliftPointDuration,
        upliftPointTimeWindows: planningOrderFormValues.upliftPointTimeWindows,
        offloadPointDuration: planningOrderFormValues.offloadPointDuration,
        offloadPointTimeWindows:
          planningOrderFormValues.offloadPointTimeWindows,
        skill: skillString,
        priority: planningOrderFormValues.priority,
        capacityDimension1: planningOrderFormValues.capacityDimension1,
        capacityDimension1Unit: planningOrderFormValues.capacityDimension1Unit,
        capacityDimension2:
          planningOrderFormValues.capacityDimension2 ?? undefined,
        capacityDimension2Unit:
          planningOrderFormValues.capacityDimension2Unit ?? undefined,
        capacityDimension3:
          planningOrderFormValues.capacityDimension3 ?? undefined,
        capacityDimension3Unit:
          planningOrderFormValues.capacityDimension3Unit ?? undefined,
        capacityDimension4:
          planningOrderFormValues.capacityDimension4 ?? undefined,
        capacityDimension4Unit:
          planningOrderFormValues.capacityDimension4Unit ?? undefined,
        collectionDurationInMinutes:
          planningOrderFormValues.collectionDurationInMinutes,
        collectionExternalReference: collectionExternalReferenceCode ?? '',
        contractCode: contractCode ?? '',
        deliverByDatetime: planningOrderFormValues.deliverByDatetime,
        deliveryDurationInMinutes:
          planningOrderFormValues.deliveryDurationInMinutes,
        deliveryExternalReference: deliveryExternalReferenceCode ?? '',
      };

      const planningOrderResponse = await planningOrderApi.apiPlanningOrderPost(
        {
          body: requestPlanningOrderAddBody,
        },
      );

      currentOrderId = planningOrderResponse.order?.id;

      await handleLinesRequest(currentOrderId);
    }

    setIsSubmitting(false);
    navigate(routes.order.list);
  };

  const handleLinesRequest = async (id: number | undefined) => {
    await Promise.all(
      lines.map(async ({product, quantity}) => {
        try {
          await orderLineApi.apiOrderLinePost({
            body: {
              orderId: id ?? 0,
              productId: product.value,
              productName: product.label,
              quantity,
            },
          });
        } catch (e) {
          toast('unable to submit Lines on order', {
            style: {borderRadius: '8px'},
            type: 'warning',
          });
        }
      }),
    );
  };

  // const onSubmit = () => {
  //   onSubmitOrderLines(lines); going to be used when purchase order is added
  // };

  return (
    <>
      <VantageTable
        config={config}
        data={lines ?? []}
        rowId="id"
        actions={hasPermissions('order:add') ? {onInlineAdd} : undefined}
        rowActions={hasPermissions('order:add') ? {onDelete} : undefined}
      />
      <Divider />
      <Stack direction="row" p={1} spacing={2}>
        <Button
          variant="contained"
          onClick={onPreviousStep}
          color="inherit"
          disabled={isSubmitting}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          startIcon={isSubmitting && <CircularProgress size={18} />}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting' : 'Submit'}
        </Button>

        {/* for future use when purchase order stuff is ready */}

        {/* <Button */}
        {/*  variant="contained" */}
        {/*  disabled={lines.length === 0} */}
        {/*  onClick={onSubmit} */}
        {/* > */}
        {/*  Next */}
        {/* </Button> */}
      </Stack>
    </>
  );
}
