import {VantageTooltip} from '../components/VantageTooltip';

export function getTooltipTableColumnValues(
  arr: string[] | undefined,
  limit: number = 3,
) {
  const tooltipTitle = arr?.join(', ');
  let content = arr?.slice(0, limit).join(', ') ?? '-';

  if (arr != null && arr.length > limit) {
    content = `${content}...`;
  }

  return <VantageTooltip title={tooltipTitle}>{content}</VantageTooltip>;
}
