import {useNavigate} from 'react-router-dom';

import HomeIcon from '@mui/icons-material/HomeRounded';
import NavigateNextIcon from '@mui/icons-material/NavigateNextRounded';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {routes} from '../../../../routes';
import {
  type Breadcrumb,
  useBreadcrumbStore,
} from '../../stores/breadcrumbsStore';

export function TopBarBreadcrumbs() {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbStore((state) => state.breadcrumbs);
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{flex: 1, color: 'inherit'}}
      separator={<NavigateNextIcon fontSize="medium" color="inherit" />}
    >
      <Stack
        sx={{cursor: breadcrumbs.length === 0 ? 'default' : 'pointer'}}
        direction="row"
        spacing={0.5}
        onClick={(event) => {
          if (event.metaKey || event.ctrlKey || event.shiftKey) {
            window.open(routes.home, '_blank', 'noreferrer');
          } else {
            navigate(routes.home);
          }
        }}
        onAuxClick={() => {
          window.open(routes.home, '_blank', 'noreferrer');
        }}
      >
        <HomeIcon color={breadcrumbs.length > 0 ? 'primary' : 'action'} />
        <Typography
          variant="body1"
          fontWeight={breadcrumbs.length > 0 ? 600 : 400}
          color={breadcrumbs.length > 0 ? 'primary' : 'text.primary'}
        >
          Home
        </Typography>
      </Stack>
      {breadcrumbs.map((breadcrumb) => (
        <AppShellTopBarBreadcrumb
          key={breadcrumb.path ?? breadcrumb.label}
          breadcrumb={breadcrumb}
        />
      ))}
    </Breadcrumbs>
  );
}

interface AppShellTopBarBreadcrumbProps {
  breadcrumb: Breadcrumb;
}

function AppShellTopBarBreadcrumb({breadcrumb}: AppShellTopBarBreadcrumbProps) {
  const navigate = useNavigate();
  return (
    <Typography
      key={breadcrumb.path}
      sx={{cursor: breadcrumb.path == null ? 'default' : 'pointer'}}
      variant="body1"
      fontWeight={400}
      color={breadcrumb.path == null ? 'text.secondary' : 'primary'}
      onClick={(event) => {
        if (breadcrumb.path != null) {
          if (event.metaKey || event.ctrlKey || event.shiftKey) {
            window.open(breadcrumb.path, '_blank', 'noreferrer');
          } else {
            navigate(breadcrumb.path);
          }
        }
      }}
      onAuxClick={() => {
        if (breadcrumb.path != null) {
          window.open(breadcrumb.path, '_blank', 'noreferrer');
        }
      }}
    >
      {breadcrumb.label}
    </Typography>
  );
}
