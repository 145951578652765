import {useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

export const useTabs = <TabValue = string>(
  defaultValue: TabValue,
  searchParamKey: string = 'tab',
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    activeTab: useMemo(() => {
      return (searchParams.get(searchParamKey) ?? defaultValue) as TabValue;
    }, [defaultValue, searchParamKey, searchParams]),
    onTabChange: (newTabValue: TabValue) => {
      setSearchParams((prev) => {
        const prevItems: Record<string, string> = {};

        prev.forEach((value, key) => {
          prevItems[key] = value;
        });
        return {
          ...prevItems,
          [searchParamKey]: newTabValue as string,
        };
      });
    },
  };
};

export type UseTabsReturnType<TabValue> = ReturnType<typeof useTabs<TabValue>>;
