import {memo} from 'react';

import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';

import {TopBarBreadcrumbs} from './TopBarBreadcrumbs';
import {TopBarDateRange} from './TopBarDateRange';
import {TopBarEvents} from './TopBarEvents';
import {getGlassBackground} from '../../../../theme/getGlassBackground';

export const TopBar = memo(function TopBar() {
  return (
    <TopAppBar color="inherit" position="sticky">
      <Stack direction="row" spacing={1} px={1.5} py={0.5} alignItems="center">
        <TopBarBreadcrumbs />
        <TopBarDateRange />
        <TopBarEvents />
      </Stack>
    </TopAppBar>
  );
});

const TopAppBar = styled(AppBar)(({theme}) => ({
  zIndex: 502,
  ...getGlassBackground(theme),
}));
