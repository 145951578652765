import {type MenuItem} from './menuItems';
import {routes} from '../../../../../routes';

export const executionMenuItems: MenuItem[] = [
  {
    level: 2,
    label: 'Orders',
    pathname: routes.order.list,
    permission: 'order:list',
  },
  {
    level: 2,
    label: 'Tasks',
    pathname: routes.task.list,
    permission: 'task:list',
  },
  {
    level: 2,
    label: 'Trips',
    pathname: routes.trip.list,
    permission: 'trip:list',
  },
  {
    level: 2,
    label: 'Workflows',
    pathname: routes.workflow.list,
    permission: 'workflow:list',
  },
];
