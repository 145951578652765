import {useEffect} from 'react';

import Typography from '@mui/material/Typography';

import {VantageAutocompleteField} from '../../../common/components/form/VantageAutocompleteField';
import {useDateRangeStore} from '../../../common/stores/dateRangeStore';
import {useLoadUnassignedOrdersListing} from '../../hooks/useLoadUnassignedOrdersListing';

export function TripAddStopsExistingOrdersAutocomplete() {
  const startDate = useDateRangeStore((state) => state.startDate);
  const endDate = useDateRangeStore((state) => state.endDate);
  const {listing, loadOrderListingStatus, onDebounceSearch, loadOrderListing} =
    useLoadUnassignedOrdersListing();

  useEffect(() => {
    void loadOrderListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <VantageAutocompleteField
      name="existingOrders"
      options={listing}
      onInputChange={onDebounceSearch}
      label="Order(s)"
      placeholder="Search and select the orders within the currenct date range"
      limitTags={10}
      loading={loadOrderListingStatus === 'loading'}
      loadingText={
        <Typography color="text.secondary">
          Loading <strong>orders</strong> listing...
        </Typography>
      }
      multiple
      formik
    />
  );
}
