import type {Dispatch, SetStateAction} from 'react';

import AddIcon from '@mui/icons-material/AddRounded';

import {TableActionButtonBase} from '../components/TableActionButtonBase';
import {TableActionDownload} from '../components/TableActionDownload';
import {TableActionSelectAll} from '../components/TableActionSelectAll';
import type {VantageTableActions, VantageTableProps} from '../VantageTable';

export interface ActionsTypeProviderEditorHeaderProps<TableData extends object>
  extends Pick<VantageTableProps<TableData>, 'data' | 'config'> {
  setAddedRows: Dispatch<SetStateAction<Array<Record<string, unknown>>>>;
  actions: VantageTableActions<TableData>;
}

export function ActionsTypeProviderEditorHeader<TableData extends object>({
  config,
  setAddedRows,
  data,
  actions,
}: ActionsTypeProviderEditorHeaderProps<TableData>) {
  return (
    <>
      {actions.selection != null && (
        <TableActionSelectAll
          selection={actions.selection}
          config={config}
          loading={false}
          data={data}
        />
      )}
      {(actions.onAdd != null || actions.onInlineAdd != null) && (
        <TableActionButtonBase
          data-cy="TableActionsAdd"
          onClick={
            actions.onInlineAdd != null
              ? () => {
                  setAddedRows([{}]);
                }
              : actions.onAdd
          }
          loading={false}
        >
          <AddIcon />
        </TableActionButtonBase>
      )}
      {actions.onDownload != null && (
        <TableActionDownload onDownload={actions.onDownload} data={data} />
      )}
    </>
  );
}
