export type Permissions = typeof permissions;
export type Permission = keyof Permissions;

export type PickPermissions<T extends keyof Permissions> = keyof Pick<
  Permissions,
  T
>;

export const permissions = {
  'audience:add': {name: 'Add Contact', type: 'Add'},
  'audience:delete': {name: 'Delete Contact', type: 'Delete'},
  'audience:edit': {name: 'Edit Contact', type: 'Edit'},
  'audience:list': {name: 'Contact List', type: 'View'},
  'audience:listDownload': {name: 'Contact ListDownload', type: 'ListDownload'},
  'audienceGroup:add': {name: 'Add ContactGroup', type: 'Add'},
  'audienceGroup:delete': {name: 'Delete ContactGroup', type: 'Delete'},
  'audienceGroup:edit': {name: 'Edit ContactGroup', type: 'Edit'},
  'audienceGroup:list': {name: 'ContactGroup List', type: 'View'},
  'audienceGroup:listDownload': {
    name: 'ContactGroup ListDownload',
    type: 'ListDownload',
  },
  'audienceGroup:listing': {name: 'ContactGroup Listing', type: 'View'},
  'audienceGroup:view': {name: 'ContactGroup', type: 'View'},
  'booking:add': {name: 'Add Booking', type: 'Add'},
  'booking:delete': {name: 'Delete Booking', type: 'Delete'},
  'booking:edit': {name: 'Edit Booking', type: 'Edit'},
  'booking:list': {name: 'Booking List', type: 'View'},
  'booking:listDownload': {name: 'Booking ListDownload', type: 'ListDownload'},
  'bookingMasterTrip:delete': {
    name: 'Delete BookingMasterTrip',
    type: 'Delete',
  },
  'broadcast:add': {name: 'Add Broadcast', type: 'Add'},
  'broadcast:delete': {name: 'Delete Broadcast', type: 'Delete'},
  'broadcast:edit': {name: 'Edit Broadcast', type: 'Edit'},
  'broadcast:list': {name: 'Broadcast List', type: 'View'},
  'broadcast:listDownload': {
    name: 'Broadcast ListDownload',
    type: 'ListDownload',
  },
  'broadcast:view': {name: 'Broadcast', type: 'View'},
  'bulkDeleteTrips:delete': {name: 'BulkDelete Trips', type: 'Delete'},
  'call:add': {name: 'Add Call', type: 'Add'},
  'call:delete': {name: 'Delete Call', type: 'Delete'},
  'call:edit': {name: 'Edit Call', type: 'Edit'},
  'call:list': {name: 'Call List', type: 'View'},
  'clientAPIDocs:view': {name: 'Client API Docs', type: 'View'},
  'contract:add': {name: 'Add Contract', type: 'Add'},
  'contract:delete': {name: 'Delete Contract', type: 'Delete'},
  'contract:edit': {name: 'Edit Contract', type: 'Edit'},
  'contract:list': {name: 'Contract List', type: 'View'},
  'contract:listDownload': {
    name: 'Contract ListDownload',
    type: 'ListDownload',
  },
  'contractBroadcastTemplate:add': {
    name: 'Add ContractBroadcastTemplate',
    type: 'Add',
  },
  'contractBroadcastTemplate:delete': {
    name: 'Delete ContractBroadcastTemplate',
    type: 'Delete',
  },
  'contractBroadcastTemplate:edit': {
    name: 'Edit ContractBroadcastTemplate',
    type: 'Edit',
  },
  'contractBroadcastTemplate:list': {
    name: 'ContractBroadcastTemplate List',
    type: 'View',
  },
  'contractGroup:add': {name: 'Add ContractGroup', type: 'Add'},
  'contractGroup:delete': {name: 'Delete ContractGroup', type: 'Delete'},
  'contractGroup:edit': {name: 'Edit ContractGroup', type: 'Edit'},
  'contractGroup:list': {name: 'ContractGroup List', type: 'View'},
  'contractGroup:listDownload': {
    name: 'ContractGroup ListDownload',
    type: 'ListDownload',
  },
  'contractMediaEventDriverReasonCode:add': {
    name: 'Add ContractMediaEventDriverReasonCode',
    type: 'Add',
  },
  'contractMediaEventDriverReasonCode:delete': {
    name: 'Delete ContractMediaEventDriverReasonCode',
    type: 'Delete',
  },
  'contractMediaEventDriverReasonCode:edit': {
    name: 'Edit ContractMediaEventDriverReasonCode',
    type: 'Edit',
  },
  'contractMediaEventDriverReasonCode:list': {
    name: 'ContractMediaEventDriverReasonCode List',
    type: 'View',
  },
  'contracts:viewAll': {name: 'All Contracts', type: 'View'},
  'contractTaskTemplate:add': {name: 'Add ContractTaskTemplate', type: 'Add'},
  'contractTaskTemplate:delete': {
    name: 'Delete ContractTaskTemplate',
    type: 'Delete',
  },
  'creditKM:edit': {name: 'Edit Credit KM', type: 'Edit'},
  'criticalEvent BulkAction:edit': {
    name: 'CriticalEvent BulkAction',
    type: 'Edit',
  },
  'criticalEvent:add': {name: 'Add CriticalEvent', type: 'Add'},
  'criticalEvent:delete': {name: 'Delete CriticalEvent', type: 'Delete'},
  'criticalEvent:edit': {name: 'Edit CriticalEvent', type: 'Edit'},
  'criticalEvent:list': {name: 'CriticalEvent List', type: 'View'},
  'criticalEventActivity:add': {name: 'Add CriticalEventActivity', type: 'Add'},
  'criticalEventActivity:delete': {
    name: 'Delete CriticalEventActivity',
    type: 'Delete',
  },
  'criticalEventType:add': {name: 'Add CriticalEventType', type: 'Add'},
  'criticalEventType:delete': {
    name: 'Delete CriticalEventType',
    type: 'Delete',
  },
  'criticalEventType:edit': {name: 'Edit CriticalEventType', type: 'Edit'},
  'criticalEventType:list': {name: 'CriticalEventType List', type: 'View'},
  'criticalEventType:listDownload': {
    name: 'CriticalEventType ListDownload',
    type: 'ListDownload',
  },
  'criticalEventType:listing': {
    name: 'CriticalEventType Listing',
    type: 'View',
  },
  'criticalEventType:view': {name: 'CriticalEventType', type: 'View'},
  'criticalEventTypeToggles:edit': {
    name: 'Edit CriticalEventTypeToggles',
    type: 'Edit',
  },
  'criticalEventVideo:list': {name: 'CriticalEventVideo List', type: 'View'},
  'customer:add': {name: 'Add Customer', type: 'Add'},
  'customer:delete': {name: 'Delete Customer', type: 'Delete'},
  'customer:edit': {name: 'Edit Customer', type: 'Edit'},
  'customer:list': {name: 'Customer List', type: 'View'},
  'customer:listDownload': {
    name: 'Customer ListDownload',
    type: 'ListDownload',
  },
  'customerSite:add': {name: 'Add CustomerSite', type: 'Add'},
  'customerSite:delete': {name: 'Delete CustomerSite', type: 'Delete'},
  'customerSite:edit': {name: 'Edit CustomerSite', type: 'Edit'},
  'customerSiteTransporter:add': {
    name: 'Add CustomerSiteTransporter',
    type: 'Add',
  },
  'customerSiteTransporter:delete': {
    name: 'Delete CustomerSiteTransporter',
    type: 'Delete',
  },
  'cypressDump:view': {name: 'Cypress Dump', type: 'View'},
  'dashboard:add': {name: 'Add Dashboard', type: 'Add'},
  'dashboard:delete': {name: 'Delete Dashboard', type: 'Delete'},
  'dashboard:edit': {name: 'Edit Dashboard', type: 'Edit'},
  'dashboard:list': {name: 'Dashboard List', type: 'View'},
  'dashboard:view': {name: 'Dashboard', type: 'View'},
  'developerAPIDocs:view': {name: 'Developer API Docs', type: 'View'},
  'divertOrder:edit': {name: 'Divert Order', type: 'Edit'},
  'document:add': {name: 'Add Document', type: 'Add'},
  'document:delete': {name: 'Delete Document', type: 'Delete'},
  'document:edit': {name: 'Edit Document', type: 'Edit'},
  'document:list': {name: 'Document List', type: 'View'},
  'document:listDownload': {
    name: 'Document ListDownload',
    type: 'ListDownload',
  },
  'document:listing': {name: 'Document Listing', type: 'View'},
  'document:view': {name: 'Document', type: 'View'},
  'documentStorage:add': {name: 'Add DocumentStorage', type: 'Add'},
  'documentStorage:delete': {name: 'Delete DocumentStorage', type: 'Delete'},
  'documentStorage:edit': {name: 'Edit DocumentStorage', type: 'Edit'},
  'documentStorage:list': {name: 'DocumentStorage List', type: 'View'},
  'documentStorage:listDownload': {
    name: 'DocumentStorage ListDownload',
    type: 'ListDownload',
  },
  'documentStorage:listing': {name: 'DocumentStorage Listing', type: 'View'},
  'documentStorage:view': {name: 'DocumentStorage', type: 'View'},
  'documentTemplate:add': {name: 'Add DocumentTemplate', type: 'Add'},
  'documentTemplate:delete': {name: 'Delete DocumentTemplate', type: 'Delete'},
  'documentTemplate:edit': {name: 'Edit DocumentTemplate', type: 'Edit'},
  'documentTemplate:list': {name: 'DocumentTemplate List', type: 'View'},
  'documentTemplate:listDownload': {
    name: 'DocumentTemplate ListDownload',
    type: 'ListDownload',
  },
  'documentType:add': {name: 'Add DocumentType', type: 'Add'},
  'documentType:delete': {name: 'Delete DocumentType', type: 'Delete'},
  'documentType:edit': {name: 'Edit DocumentType', type: 'Edit'},
  'documentType:list': {name: 'DocumentType List', type: 'View'},
  'documentType:listDownload': {
    name: 'DocumentType ListDownload',
    type: 'ListDownload',
  },
  'documentType:listing': {name: 'DocumentType Listing', type: 'View'},
  'documentType:view': {name: 'DocumentType', type: 'View'},
  'driver:add': {name: 'Add Driver', type: 'Add'},
  'driver:delete': {name: 'Delete Driver', type: 'Delete'},
  'driver:edit': {name: 'Edit Driver', type: 'Edit'},
  'driver:list': {name: 'Driver List', type: 'View'},
  'driver:listDownload': {name: 'Driver ListDownload', type: 'ListDownload'},
  'driverAdhocTask:list': {name: 'Driver Adhoc Task List', type: 'View'},
  'driverCriticalEvent:edit': {name: 'Edit DriverCriticalEvent', type: 'Edit'},
  'driverCriticalEvent:list': {name: 'DriverCriticalEvent List', type: 'View'},
  'driverDailyTask:list': {name: 'Driver Daily Task List', type: 'View'},
  'driverDebrief:list': {name: 'DriverDebrief List', type: 'View'},
  'driverDebrief:view': {name: 'Add DriverDebrief', type: 'View'},
  'driverDocument:add': {name: 'Add DriverDocument', type: 'Add'},
  'driverDocument:delete': {name: 'Delete DriverDocument', type: 'Delete'},
  'driverDocument:edit': {name: 'Edit DriverDocument', type: 'Edit'},
  'driverDocument:list': {name: 'DriverDocument List', type: 'View'},
  'driverDocument:listDownload': {
    name: 'DriverDocument ListDownload',
    type: 'ListDownload',
  },
  'driverDocument:listing': {name: 'DriverDocument Listing', type: 'View'},
  'driverDocument:view': {name: 'DriverDocument', type: 'View'},
  'driverDocumentActionLog:add': {
    name: 'Add DriverDocumentActionLog',
    type: 'Add',
  },
  'driverDocumentActionLog:delete': {
    name: 'Delete DriverDocumentActionLog',
    type: 'Delete',
  },
  'driverDocumentActionLog:edit': {
    name: 'Edit DriverDocumentActionLog',
    type: 'Edit',
  },
  'driverDocumentActionLog:list': {
    name: 'DriverDocumentActionLog List',
    type: 'View',
  },
  'driverDocumentActionLog:listDownload': {
    name: 'DriverDocumentActionLog ListDownload',
    type: 'ListDownload',
  },
  'driverDocumentActionLog:listing': {
    name: 'DriverDocumentActionLog Listing',
    type: 'View',
  },
  'driverDocumentActionLog:view': {
    name: 'DriverDocumentActionLog',
    type: 'View',
  },
  'driverDriverGroupAssign:Assign': {
    name: 'Driver DriverGroup Assign',
    type: 'Assign',
  },
  'driverEvents:view': {name: 'Driver Events', type: 'View'},
  'driverGroup:add': {name: 'Add DriverGroup', type: 'Add'},
  'driverGroup:edit': {name: 'Edit DriverGroup', type: 'Edit'},
  'driverGroup:list': {name: 'DriverGroup List', type: 'View'},
  'driverLink:edit': {name: 'Driver Link', type: 'Edit'},
  'driverMediaEvent:add': {name: 'Add DriverMediaEvent', type: 'Add'},
  'driverMediaEvent:delete': {name: 'Delete DriverMediaEvent', type: 'Delete'},
  'driverMediaEvent:edit': {name: 'Edit DriverMediaEvent', type: 'Edit'},
  'driverMediaEvent:list': {name: 'DriverMediaEvent List', type: 'View'},
  'driverMediaEvent:listDownload': {
    name: 'DriverMediaEvent ListDownload',
    type: 'ListDownload',
  },
  'driverMediaEvent:listing': {name: 'DriverMediaEvent Listing', type: 'View'},
  'driverMediaEvent:view': {name: 'DriverMediaEvent', type: 'View'},
  'driverOperationalEvent:edit': {
    name: 'Edit DriverOperationalEvent',
    type: 'Edit',
  },
  'driverOperationalEvent:list': {
    name: 'DriverOperationalEvent List',
    type: 'View',
  },
  'driverRankIncentive:add': {name: 'Add DriverRankIncentive', type: 'Add'},
  'driverRankIncentive:delete': {
    name: 'Delete DriverRankIncentive',
    type: 'Delete',
  },
  'driverRankIncentive:edit': {name: 'Edit DriverRankIncentive', type: 'Edit'},
  'driverRankIncentive:list': {name: 'DriverRankIncentive List', type: 'View'},
  'driverRankIncentive:listDownload': {
    name: 'DriverRankIncentive ListDownload',
    type: 'ListDownload',
  },
  'driverRankIncentive:listing': {
    name: 'DriverRankIncentive Listing',
    type: 'View',
  },
  'driverRankIncentive:view': {name: 'DriverRankIncentive', type: 'View'},
  'driverRankMe:view': {name: 'Driver Rank Me', type: 'View'},
  'driverSafety:view': {name: 'Driver Safety', type: 'View'},
  'driverTrip:list': {name: 'Driver Trip List', type: 'View'},
  'driverUserAssign:edit': {name: 'Driver User Assign', type: 'Edit'},
  'edge:add': {name: 'Add Edge', type: 'Add'},
  'escalation:add': {name: 'Add Escalation', type: 'Add'},
  'escalation:list': {name: 'Escalation List', type: 'View'},
  'eventTab:view': {name: 'EventTab', type: 'View'},
  'externalOrderLine:edit': {name: 'Edit ExternalOrderLine', type: 'Edit'},
  'externalVehicle:list': {name: 'ExternalVehicle List', type: 'View'},
  'externalVehicleType:list': {name: 'ExternalVehicleType List', type: 'View'},
  'FM:list': {name: 'FM List', type: 'View'},
  'functionalDocs:view': {name: 'Functional Docs', type: 'View'},
  'generateLoginQR:view': {name: 'Generate Login QR', type: 'View'},
  'gisAnalysis:view': {name: 'Gis Analysis', type: 'View'},
  'health:list': {name: 'Health List', type: 'View'},
  'healthCheck:view': {name: 'Health Check', type: 'View'},
  'integration Retry:Retry': {name: 'Integration Retry', type: 'Retry'},
  'integration:list': {name: 'Integration List', type: 'View'},
  'integrationAPIDocs:view': {name: 'Integration API Docs', type: 'View'},
  'internalIntegration:Integration': {
    name: 'InternalIntegration',
    type: 'Integration',
  },
  'lastVehiclePosition:list': {name: 'LastVehiclePosition List', type: 'View'},
  'liveVideoStream:view': {name: 'LiveVideoStream', type: 'View'},
  'masterRoute:add': {name: 'Add MasterRoute', type: 'Add'},
  'masterRoute:delete': {name: 'Delete MasterRoute', type: 'Delete'},
  'masterRoute:edit': {name: 'Edit MasterRoute', type: 'Edit'},
  'masterRoute:list': {name: 'MasterRoute List', type: 'View'},
  'masterRoute:listDownload': {
    name: 'MasterRoute ListDownload',
    type: 'ListDownload',
  },
  'masterTrip:add': {name: 'Add MasterTrip', type: 'Add'},
  'masterTrip:delete': {name: 'Delete MasterTrip', type: 'Delete'},
  'masterTrip:edit': {name: 'Edit MasterTrip', type: 'Edit'},
  'masterTripDebriefConfig:add': {
    name: 'Add MasterTripDebriefConfig',
    type: 'Add',
  },
  'masterTripDebriefConfig:delete': {
    name: 'Delete MasterTripDebriefConfig',
    type: 'Delete',
  },
  'masterTripDebriefConfig:edit': {
    name: 'Edit MasterTripDebriefConfig',
    type: 'Edit',
  },
  'masterTripDebriefConfig:list': {
    name: 'MasterTripDebriefConfig List',
    type: 'View',
  },
  'masterTripDebriefConfig:listDownload': {
    name: 'MasterTripDebriefConfig ListDownload',
    type: 'ListDownload',
  },
  'masterTripDebriefConfig:view': {
    name: 'MasterTripDebriefConfig',
    type: 'View',
  },
  'mediaEvent:add': {name: 'Add MediaEvent', type: 'Add'},
  'mediaEvent:delete': {name: 'Delete MediaEvent', type: 'Delete'},
  'mediaEvent:edit': {name: 'Edit MediaEvent', type: 'Edit'},
  'mediaEvent:list': {name: 'MediaEvent List', type: 'View'},
  'mediaEvent:listDownload': {
    name: 'MediaEvent ListDownload',
    type: 'ListDownload',
  },
  'mediaEvent:listing': {name: 'MediaEvent Listing', type: 'View'},
  'mediaEvent:view': {name: 'MediaEvent', type: 'View'},
  'mediaEventMedia:list': {name: 'MediaEventMedia List', type: 'View'},
  'multiTripDebriefApprove:edit': {
    name: 'Multi Trip Debrief Approve',
    type: 'Edit',
  },
  'mutedCriticalEventType:add': {
    name: 'Add MutedCriticalEventType',
    type: 'Add',
  },
  'mutedCriticalEventType:delete': {
    name: 'Delete MutedCriticalEventType',
    type: 'Delete',
  },
  'mutedCriticalEventType:edit': {
    name: 'Edit MutedCriticalEventType',
    type: 'Edit',
  },
  'mutedCriticalEventType:list': {
    name: 'MutedCriticalEventType List',
    type: 'View',
  },
  'mutedOperationalEventType:add': {
    name: 'Add MutedOperationalEventType',
    type: 'Add',
  },
  'mutedOperationalEventType:delete': {
    name: 'Delete MutedOperationalEventType',
    type: 'Delete',
  },
  'mutedOperationalEventType:edit': {
    name: 'Edit MutedOperationalEventType',
    type: 'Edit',
  },
  'mutedOperationalEventType:list': {
    name: 'MutedOperationalEventType List',
    type: 'View',
  },
  'notificationConfig:add': {name: 'Add NotificationConfig', type: 'Add'},
  'notificationConfig:delete': {
    name: 'Delete NotificationConfig',
    type: 'Delete',
  },
  'notificationConfig:edit': {name: 'Edit NotificationConfig', type: 'Edit'},
  'notificationConfig:list': {name: 'NotificationConfig List', type: 'View'},
  'notificationConfig:listDownload': {
    name: 'NotificationConfig ListDownload',
    type: 'ListDownload',
  },
  'operationalEvent:add': {name: 'Add OperationalEvent', type: 'Add'},
  'operationalEvent:delete': {name: 'Delete OperationalEvent', type: 'Delete'},
  'operationalEvent:edit': {name: 'Edit OperationalEvent', type: 'Edit'},
  'operationalEvent:list': {name: 'OperationalEvent List', type: 'View'},
  'operationalEventActivity:add': {
    name: 'Add OperationalEventActivity',
    type: 'Add',
  },
  'operationalEventActivity:delete': {
    name: 'Delete OperationalEventActivity',
    type: 'Delete',
  },
  'operationalEventType:add': {name: 'Add OperationalEventType', type: 'Add'},
  'operationalEventType:delete': {
    name: 'Delete OperationalEventType',
    type: 'Delete',
  },
  'operationalEventType:edit': {
    name: 'Edit OperationalEventType',
    type: 'Edit',
  },
  'operationalEventType:list': {
    name: 'OperationalEventType List',
    type: 'View',
  },
  'operationalEventType:listDownload': {
    name: 'OperationalEventType ListDownload',
    type: 'ListDownload',
  },
  'operationalEventType:listing': {
    name: 'OperationalEventType Listing',
    type: 'View',
  },
  'operationalEventType:view': {name: 'OperationalEventType', type: 'View'},
  'operationalEventTypeToggles:edit': {
    name: 'Edit OperationalEventTypeToggles',
    type: 'Edit',
  },
  'order:add': {name: 'Add Order', type: 'Add'},
  'order:delete': {name: 'Delete Order', type: 'Delete'},
  'order:edit': {name: 'Edit Order', type: 'Edit'},
  'order:list': {name: 'Order List', type: 'View'},
  'order:listDownload': {name: 'Order ListDownload', type: 'ListDownload'},
  'orderCancelledSubmit:BlockField': {
    name: 'Block Order Cancelled Submit',
    type: 'BlockField',
  },
  'orderHeaderActualDispatch:BlockField': {
    name: 'BlockField OrderHeader Actual Dispatch',
    type: 'BlockField',
  },
  'orderHeaderContract:BlockField': {
    name: 'BlockField OrderHeader Contract',
    type: 'BlockField',
  },
  'orderHeaderCustomer:BlockField': {
    name: 'BlockField OrderHeader Customer',
    type: 'BlockField',
  },
  'orderHeaderCustomerReferenceNumber:BlockField': {
    name: 'BlockField OrderHeader Customer Reference Number',
    type: 'BlockField',
  },
  'orderHeaderDeliveryNoteNumber:BlockField': {
    name: 'BlockField OrderHeader Delivery Note Number',
    type: 'BlockField',
  },
  'orderHeaderOffloadPoint:BlockField': {
    name: 'BlockField OrderHeader Offload Point',
    type: 'BlockField',
  },
  'orderHeaderPlannedDepotDeparture:BlockField': {
    name: 'BlockField OrderHeader Planned Depot Departure',
    type: 'BlockField',
  },
  'orderHeaderPrimeMover:BlockField': {
    name: 'BlockField OrderHeader Prime Mover',
    type: 'BlockField',
  },
  'orderHeaderStatus:BlockField': {
    name: 'BlockField OrderHeader Status',
    type: 'BlockField',
  },
  'orderHeaderTransporter:BlockField': {
    name: 'BlockField OrderHeader Transporter',
    type: 'BlockField',
  },
  'orderHeaderUpliftPoint:BlockField': {
    name: 'BlockField OrderHeader Uplift Point',
    type: 'BlockField',
  },
  'orderLine:add': {name: 'Add OrderLine', type: 'Add'},
  'orderLine:delete': {name: 'Delete OrderLine', type: 'Delete'},
  'orderLine:edit': {name: 'Edit OrderLine', type: 'Edit'},
  'orderLinePlannedQuantity:BlockField': {
    name: 'Block OrderLine Planned Quantity',
    type: 'BlockField',
  },
  'orderLineProductName:BlockField': {
    name: 'Block OrderLine Product Name',
    type: 'BlockField',
  },
  'orderNumber:edit': {name: 'Edit OrderNumber', type: 'Edit'},
  'permission:add': {name: 'Add Permission', type: 'Add'},
  'permission:delete': {name: 'Delete Permission', type: 'Delete'},
  'permission:edit': {name: 'Edit Permission', type: 'Edit'},
  'permission:list': {name: 'Permission List', type: 'View'},
  'permission:listDownload': {
    name: 'Permission ListDownload',
    type: 'ListDownload',
  },
  'planningBoard:edit': {name: 'Edit PlanningBoard', type: 'Edit'},
  'planningBoard:view': {name: 'PlanningBoard View', type: 'View'},
  'planningMasterTrip:delete': {
    name: 'Delete PlanningMasterTrip',
    type: 'Delete',
  },
  'planningOrder:add': {name: 'Add PlanningOrder', type: 'Add'},
  'planningOrder:delete': {name: 'Delete PlanningOrder', type: 'Delete'},
  'planningOrder:edit': {name: 'Edit PlanningOrder', type: 'Edit'},
  'planningOrder:list': {name: 'PlanningOrder List', type: 'View'},
  'planningOrder:listDownload': {
    name: 'PlanningOrder ListDownload',
    type: 'ListDownload',
  },
  'planningOrder:listing': {name: 'PlanningOrder Listing', type: 'View'},
  'planningOrder:view': {name: 'PlanningOrder', type: 'View'},
  'planningSkill:add': {name: 'Add PlanningSkill', type: 'Add'},
  'planningSkill:delete': {name: 'Delete PlanningSkill', type: 'Delete'},
  'planningSkill:edit': {name: 'Edit PlanningSkill', type: 'Edit'},
  'planningSkill:list': {name: 'PlanningSkill List', type: 'View'},
  'planningSkill:listDownload': {
    name: 'PlanningSkill ListDownload',
    type: 'ListDownload',
  },
  'planningSolution:add': {name: 'Add PlanningSolution', type: 'Add'},
  'planningSolution:delete': {name: 'Delete PlanningSolution', type: 'Delete'},
  'planningSolution:edit': {name: 'Edit PlanningSolution', type: 'Edit'},
  'planningSolution:list': {name: 'PlanningSolution List', type: 'View'},
  'product:add': {name: 'Add Product', type: 'Add'},
  'product:delete': {name: 'Delete Product', type: 'Delete'},
  'product:edit': {name: 'Edit Product', type: 'Edit'},
  'product:list': {name: 'Product List', type: 'View'},
  'product:listDownload': {name: 'Product ListDownload', type: 'ListDownload'},
  'productGroup:add': {name: 'Add ProductGroup', type: 'Add'},
  'productGroup:delete': {name: 'Delete ProductGroup', type: 'Delete'},
  'productGroup:edit': {name: 'Edit ProductGroup', type: 'Edit'},
  'productGroup:list': {name: 'ProductGroup List', type: 'View'},
  'productGroup:listDownload': {
    name: 'ProductGroup ListDownload',
    type: 'ListDownload',
  },
  'productGroup:listing': {name: 'ProductGroup Listing', type: 'View'},
  'productGroup:view': {name: 'ProductGroup', type: 'View'},
  'profile:add': {name: 'Add Profile', type: 'Add'},
  'profile:edit': {name: 'Edit Profile', type: 'Edit'},
  'profile:list': {name: 'Profile List', type: 'View'},
  'report:add': {name: 'Add Report', type: 'Add'},
  'report:delete': {name: 'Delete Report', type: 'Delete'},
  'report:edit': {name: 'Edit Report', type: 'Edit'},
  'report:list': {name: 'Report List', type: 'View'},
  'report:listDownload': {name: 'Report ListDownload', type: 'ListDownload'},
  'reportAdmin:view': {name: 'Report Admin', type: 'View'},
  'reportSchedule:add': {name: 'Add ReportSchedule', type: 'Add'},
  'reportSchedule:delete': {name: 'Delete ReportSchedule', type: 'Delete'},
  'reportSchedule:edit': {name: 'Edit ReportSchedule', type: 'Edit'},
  'reportSchedule:list': {name: 'ReportSchedule List', type: 'View'},
  'reportSchedule:listDownload': {
    name: 'ReportSchedule ListDownload',
    type: 'ListDownload',
  },
  'reportSchedule:listing': {name: 'ReportSchedule Listing', type: 'View'},
  'reportSchedule:view': {name: 'ReportSchedule', type: 'View'},
  'reportScheduleContact:add': {name: 'Add ReportScheduleContact', type: 'Add'},
  'reportScheduleContact:delete': {
    name: 'Delete ReportScheduleContact',
    type: 'Delete',
  },
  'reportScheduleContact:edit': {
    name: 'Edit ReportScheduleContact',
    type: 'Edit',
  },
  'reportScheduleContact:list': {
    name: 'ReportScheduleContact List',
    type: 'View',
  },
  'reportScheduleContact:listDownload': {
    name: 'ReportScheduleContact ListDownload',
    type: 'ListDownload',
  },
  'reportScheduleContact:listing': {
    name: 'ReportScheduleContact Listing',
    type: 'View',
  },
  'reportScheduleContact:view': {name: 'ReportScheduleContact', type: 'View'},
  'revisedETA:view': {name: 'Revised ETA', type: 'View'},
  'role:add': {name: 'Add Role', type: 'Add'},
  'role:delete': {name: 'Delete Role', type: 'Delete'},
  'role:edit': {name: 'Edit Role', type: 'Edit'},
  'role:list': {name: 'Role List', type: 'View'},
  'role:listDownload': {name: 'Role ListDownload', type: 'ListDownload'},
  'roleDashboard:add': {name: 'Add RoleDashboard', type: 'Add'},
  'roleDashboard:delete': {name: 'Delete RoleDashboard', type: 'Delete'},
  'rolePermission:add': {name: 'Add RolePermission', type: 'Add'},
  'rolePermission:delete': {name: 'Delete RolePermission', type: 'Delete'},
  'roleReport:add': {name: 'Add RoleReport', type: 'Add'},
  'roleReport:delete': {name: 'Delete RoleReport', type: 'Delete'},
  'secondaryOptimisation:list': {
    name: 'SecondaryOptimisation List',
    type: 'View',
  },
  'site:add': {name: 'Add Site', type: 'Add'},
  'site:delete': {name: 'Delete Site', type: 'Delete'},
  'site:edit': {name: 'Edit Site', type: 'Edit'},
  'site:list': {name: 'Site List', type: 'View'},
  'site:listDownload': {name: 'Site ListDownload', type: 'ListDownload'},
  'siteInfo:view': {name: 'Site Info', type: 'View'},
  'siteType:add': {name: 'Add NodeType', type: 'Add'},
  'siteType:delete': {name: 'Delete NodeType', type: 'Delete'},
  'siteType:edit': {name: 'Edit NodeType', type: 'Edit'},
  'siteType:list': {name: 'NodeType List', type: 'View'},
  'siteType:listDownload': {
    name: 'NodeType ListDownload',
    type: 'ListDownload',
  },
  'speedTable:add': {name: 'Add SpeedTable', type: 'Add'},
  'speedTable:delete': {name: 'Delete SpeedTable', type: 'Delete'},
  'speedTable:edit': {name: 'Edit SpeedTable', type: 'Edit'},
  'speedTable:list': {name: 'SpeedTable List', type: 'View'},
  'speedTable:listDownload': {
    name: 'SpeedTable ListDownload',
    type: 'ListDownload',
  },
  'speedTable:view': {name: 'SpeedTable', type: 'View'},
  'storage:add': {name: 'Add Storage', type: 'Add'},
  superadmin: {name: 'superadmin', type: 'superadmin'},
  'support:view': {name: 'Support', type: 'View'},
  'task:edit': {name: 'Edit Task', type: 'Edit'},
  'task:list': {name: 'Task List', type: 'View'},
  'taskTemplate:add': {name: 'Add TaskTemplate', type: 'Add'},
  'taskTemplate:delete': {name: 'Delete TaskTemplate', type: 'Delete'},
  'taskTemplate:edit': {name: 'Edit TaskTemplate', type: 'Edit'},
  'taskTemplate:list': {name: 'TaskTemplate List', type: 'View'},
  'taskTemplate:listDownload': {
    name: 'TaskTemplate ListDownload',
    type: 'ListDownload',
  },
  'taskTemplateOption:add': {name: 'Add TaskTemplateOption', type: 'Add'},
  'taskTemplateOption:delete': {
    name: 'Delete TaskTemplateOption',
    type: 'Delete',
  },
  'taskTemplateOption:edit': {name: 'Edit TaskTemplateOption', type: 'Edit'},
  'taskTemplateOption:list': {name: 'TaskTemplateOption List', type: 'View'},
  'taskTemplateOption:listDownload': {
    name: 'TaskTemplateOption ListDownload',
    type: 'ListDownload',
  },
  'telematicsEvent:add': {name: 'Add TelematicsEvent', type: 'Add'},
  'trackingAPIDocs:view': {name: 'Tracking API Docs', type: 'View'},
  'trainingCourse:add': {name: 'Add TrainingCourse', type: 'Add'},
  'trainingCourse:delete': {name: 'Delete TrainingCourse', type: 'Delete'},
  'trainingCourse:edit': {name: 'Edit TrainingCourse', type: 'Edit'},
  'trainingCourse:list': {name: 'TrainingCourse List', type: 'View'},
  'trainingCourse:listDownload': {
    name: 'TrainingCourse ListDownload',
    type: 'ListDownload',
  },
  'transporter:add': {name: 'Add Transporter', type: 'Add'},
  'transporter:delete': {name: 'Delete Transporter', type: 'Delete'},
  'transporter:edit': {name: 'Edit Transporter', type: 'Edit'},
  'transporter:list': {name: 'Transporter List', type: 'View'},
  'transporter:listDownload': {
    name: 'Transporter ListDownload',
    type: 'ListDownload',
  },
  'trip:add': {name: 'Add Trip', type: 'Add'},
  'trip:delete': {name: 'Delete Trip', type: 'Delete'},
  'trip:list': {name: 'Trip List', type: 'View'},
  'trip:listDownload': {name: 'Trip ListDownload', type: 'ListDownload'},
  'trip:view': {name: 'Trip', type: 'View'},
  'tripComment:add': {name: 'Add TripComment', type: 'Add'},
  'tripComment:delete': {name: 'Delete TripComment', type: 'Delete'},
  'tripDebrief:edit': {name: 'Trip Debrief', type: 'Edit'},
  'tripDebrief:list': {name: 'TripDebrief List', type: 'View'},
  'tripDebriefApprove:edit': {name: 'Trip Debrief Approve', type: 'Edit'},
  'tripDebriefTrip:list': {name: 'TripDebriefTrip List', type: 'View'},
  'tripDebriefUnapprove:edit': {name: 'Trip Debrief Unapprove', type: 'Edit'},
  'tripDriverAssign:Assign': {name: 'Trip Driver Assign', type: 'Assign'},
  'tripNumber:edit': {name: 'Edit TripNumber', type: 'Edit'},
  'tripStop:edit': {name: 'Edit TripStop', type: 'Edit'},
  'tripStopComment:add': {name: 'Add TripStopComment', type: 'Add'},
  'tripStopComment:delete': {name: 'Delete TripStopComment', type: 'Delete'},
  'tripVehicleAssign:Assign': {name: 'Trip Vehicle Assign', type: 'Assign'},
  'uploadData:view': {name: 'Upload Data', type: 'View'},
  'user:add': {name: 'Add User', type: 'Add'},
  'user:delete': {name: 'Delete User', type: 'Delete'},
  'user:edit': {name: 'Edit User', type: 'Edit'},
  'user:list': {name: 'User List', type: 'View'},
  'user:listDownload': {name: 'User ListDownload', type: 'ListDownload'},
  'user:listing': {name: 'User Listing', type: 'View'},
  'userContract:add': {name: 'Add UserContract', type: 'Add'},
  'userContract:delete': {name: 'Delete UserContract', type: 'Delete'},
  'userCustomer:add': {name: 'Add UserCustomer', type: 'Add'},
  'userCustomer:delete': {name: 'Delete UserCustomer', type: 'Delete'},
  'userPasswordChange:edit': {name: 'User Password Change', type: 'Edit'},
  'userQR:view': {name: 'User QR', type: 'View'},
  'userRole:add': {name: 'Add UserRole', type: 'Add'},
  'userRole:delete': {name: 'Delete UserRole', type: 'Delete'},
  'userTrainingCourse Edit:edit': {
    name: 'UserTrainingCourse Edit',
    type: 'Edit',
  },
  'userTrainingCourse:add': {name: 'Add UserTrainingCourse', type: 'Add'},
  'userTrainingCourse:delete': {
    name: 'Delete UserTrainingCourse',
    type: 'Delete',
  },
  'userTrainingCourse:edit': {name: 'Edit UserTrainingCourse', type: 'Edit'},
  'userTrainingCourse:list': {name: 'UserTrainingCourse List', type: 'View'},
  'userTrainingCourse:listDownload': {
    name: 'UserTrainingCourse ListDownload',
    type: 'ListDownload',
  },
  'vantageLabs:view': {name: 'View Vantage Labs', type: 'View'},
  'vehicle:add': {name: 'Add Vehicle', type: 'Add'},
  'vehicle:delete': {name: 'Delete Vehicle', type: 'Delete'},
  'vehicle:edit': {name: 'Edit Vehicle', type: 'Edit'},
  'vehicle:list': {name: 'Vehicle List', type: 'View'},
  'vehicle:listDownload': {name: 'Vehicle ListDownload', type: 'ListDownload'},
  'vehicle:view': {name: 'Vehicle', type: 'View'},
  'vehicleAdhocTask:list': {name: 'Vehicle Adhoc Task List', type: 'View'},
  'vehicleCapacityDimension:add': {
    name: 'Add VehicleCapacityDimension',
    type: 'Add',
  },
  'vehicleCapacityDimension:delete': {
    name: 'Delete VehicleCapacityDimension',
    type: 'Delete',
  },
  'vehicleCapacityDimension:edit': {
    name: 'Edit VehicleCapacityDimension',
    type: 'Edit',
  },
  'vehicleCapacityDimension:list': {
    name: 'VehicleCapacityDimension List',
    type: 'View',
  },
  'vehicleCapacityDimension:listDownload': {
    name: 'VehicleCapacityDimension ListDownload',
    type: 'ListDownload',
  },
  'vehicleCompartment:add': {name: 'Add VehicleCompartment', type: 'Add'},
  'vehicleCompartment:delete': {
    name: 'Delete VehicleCompartment',
    type: 'Delete',
  },
  'vehicleCompartment:edit': {name: 'Edit VehicleCompartment', type: 'Edit'},
  'vehicleCompartment:list': {name: 'VehicleCompartment List', type: 'View'},
  'vehicleCompartment:listDownload': {
    name: 'VehicleCompartment ListDownload',
    type: 'ListDownload',
  },
  'vehicleDailyTask:list': {name: 'Vehicle Daily Task List', type: 'View'},
  'vehicleGroup:add': {name: 'Add VehicleGroup', type: 'Add'},
  'vehicleGroup:delete': {name: 'Delete VehicleGroup', type: 'Delete'},
  'vehicleGroup:edit': {name: 'Edit VehicleGroup', type: 'Edit'},
  'vehicleGroup:list': {name: 'VehicleGroup List', type: 'View'},
  'vehicleGroup:listDownload': {
    name: 'VehicleGroup ListDownload',
    type: 'ListDownload',
  },
  'vehicleGroup:view': {name: 'VehicleGroup', type: 'View'},
  'vehicleTrip:list': {name: 'Vehicle Trip List', type: 'View'},
  'vehicleType:add': {name: 'Add VehicleType', type: 'Add'},
  'vehicleType:delete': {name: 'Delete VehicleType', type: 'Delete'},
  'vehicleType:edit': {name: 'Edit VehicleType', type: 'Edit'},
  'vehicleType:list': {name: 'VehicleType List', type: 'View'},
  'vehicleType:listDownload': {
    name: 'VehicleType ListDownload',
    type: 'ListDownload',
  },
  'vehicleVehicleGroupAssign:Assign': {
    name: 'Vehicle VehicleGroup Assign',
    type: 'Assign',
  },
  'workflow:delete': {name: 'Delete Workflow', type: 'Delete'},
  'workflow:list': {name: 'Workflow List', type: 'View'},
  'workflowConfig:add': {name: 'Add WorkflowConfig', type: 'Add'},
  'workflowConfig:delete': {name: 'Delete WorkflowConfig', type: 'Delete'},
  'workflowConfig:edit': {name: 'Edit WorkflowConfig', type: 'Edit'},
  'workflowConfig:list': {name: 'WorkflowConfig List', type: 'View'},
  'yardFlowTask:list': {name: 'Yard Flow Task List', type: 'View'},
} as const;
